import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Layout } from "components/Layout";
import { Speakers } from "components/Speakers";
import { Title } from "components/Title";
import { FC } from "react";
import LazyLoad from "react-lazy-load";
import styled from "styled-components";

const StyledSpeakers = styled(Speakers)`
  margin-bottom: 8rem;
`;

export const SpeakersView: FC = () => {
  return (
    <Layout header={<Header />} footer={<Footer />}>
      <Title marginTopRem={4}>Speakers</Title>
      <LazyLoad>
        <StyledSpeakers />
      </LazyLoad>
    </Layout>
  );
};

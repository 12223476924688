import { FC } from "react";
import { createGlobalStyle } from "styled-components";
import { queries } from "utils/queries";
import { ReactComponent as Gradient } from "./gradient.svg";
const Styles = createGlobalStyle`
  :root {
    --primary-color: #0000ff;
    --secondary-color: #9D9D9C;
    --white-color: #fff;
    --black-color: #000; 
    --error-color: #D8000C;
    --primary-font: Montserrat;
  }
  body {
    font-family: Helvetica, sans-serif;
    font-size: 16px;
    color: var(--white-color);
    background: var(--secondary-color);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    
  }
  h1 {
    font-family: var(--primary-font), sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 48px;
    
    ${queries.md} {
      font-size: 72px;
      line-height: 72px;
    }
    ${queries.lg} {
      font-size: 88px;
      line-height: 88px;
    }
  }
  h2 {
    font-family: var(--primary-font), sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 32px;
    
    ${queries.md} {
      font-size: 48px;
      line-height: 48px;
    }
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  button {
    cursor: pointer;
  }
  a {
    color: var(--white-color);
    text-decoration: none;
    font-weight: bold;
  }
  ul li {
  list-style-type: none;
  }
  select:hover, label[for]:hover {
    background: var(--primary-color);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    color: var(--primary-color);
    -webkit-background-clip: text;
    background-clip: text;
    fill:  var(--primary-color);
  }
`;
export const GlobalStyles: FC = () => {
  return (
    <>
      <Styles />
      <Gradient />
    </>
  );
};

import { GradientLink } from "components/GradientLink";
import { FC } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { queries } from "utils/queries";
import LazyLoad from "react-lazy-load"

export const Logo: FC = () => (
  <img className="Logo" src="/public/images/PCDC_lines_logo.svg" alt="PCDC logo" />
);

const StyledLogo = styled.img`
  ${queries.onlySM} {
    width: 100%;
    padding: 2px;
  }
`;

export const HeaderLogo: FC = () => {
  const { pathname } = useLocation();

  if (pathname === "/") {
    return <StyledLogo src="/images/PCDC_lines_logo.svg" />;
  }

  return (
    <GradientLink to="/">
      <LazyLoad height={"100%"} width={"100%"}>
        <StyledLogo src="/images/PCDC_lines_logo.svg" />
      </LazyLoad>
    </GradientLink>
  );
};

import { GradientA } from "components/GradientLink";
import { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const TosLink: FC = () => {
  const { t } = useTranslation();

  const lang = localStorage.getItem("lang");
  const dataProtectionLink =
    lang === "cz"
      ? "https://operatorict.cz/osobni-udaje/"
      : "https://operatorict.cz/en/personal-data-protection/";

  return (
    <GradientA href={dataProtectionLink} target="_blank" rel="noreferrer">
      {t(`agreementCheckText:linkTitle`)}
    </GradientA>
  );
};

const StyledText = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

export const AgreementText: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledText>
      {t(`agreementCheckText:firstPart`)} <TosLink /> {t(`agreementCheckText:secondPart`)}
    </StyledText>
  );
};

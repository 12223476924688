export const links = (currentLang: any) => {
    return [
        {
            label: "Home",
            link: "/",
            external: false,
        },
        { label: "Program", link: "/program" },
        { label: "Speakers", link: "/speakers" },
        { label: "Venue", link: "/#venue" },
        { label: "Video", link: "/archive/2022" },
    ];
};

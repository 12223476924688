import styled, { css } from "styled-components";
import { queries } from "utils/queries";

const sharedButtonStyles = css`
  font-family: var(--primary-font), sans-serif;
  font-weight: bold;
  border-radius: 52px;
  width: 100%;

  @media screen and (min-width: 600px) {
    width: 20rem;
  }

  &:hover,
  &:hover * {
    /* -webkit-background-clip: border-box; */
    /* background-clip: border-box; */
    color: var(--primary-color);
    fill: var(--primary-color);
    background: var(--white-color);
  }

  &:hover * {
    background: none;
  }
`;

export const SecondaryButton = styled.button`
  ${sharedButtonStyles}

  font-size: 16px;
  padding: 12px 32px;
  line-height: 24px;
  border: 2px solid var(--white-color);
  color: var(--white-color);
  background-color: transparent;

  &:hover {
    border-color: transparent;
  }
`;

export const PrimaryButton = styled.button`
  ${sharedButtonStyles}

  background: var( --primary-color);
  line-height: 40px;
  font-size: 20px;
  color: var(--white-color);
  border: none;
  padding: 16px 32px;

  ${queries.md} {
    font-size: 24px;
  }

  & * {
    fill: var(--white-color);
  }
`;




export const topicsDataPresentetion2020 = [
    {
      title: "Digitals cities",
      subTitle: "Bahanur Nasya",
      //href: './presentations/Bahanur_Nasya.pdf'
      href: `${process.env.PUBLIC_URL}/old/presentations/Bahanur_Nasya.pdf`,
    
    },
    {
        title: "TMaaS CDC Prague",
        subTitle: "Evelien Marlier",
        href: `${process.env.PUBLIC_URL}/old/presentations/Evelien_Marlier.pdf`,
        
      },
    {
        
        title: "Interactive Simulation",
        subTitle: "Ira Winder",
        href: `${process.env.PUBLIC_URL}/old/presentations/Ira_Winder.pdf`,
        
    },
    {
        
        title: "Open data Api source",
        subTitle: "Jakub Nesetril",
        href: `${process.env.PUBLIC_URL}/old/presentations/Jakub_Nesetril.pdf`,
       
    },
    { 
        title: "Street level assessment",
        subTitle: "J.Resler, J.Geletič, P.Krč",
        href: `${process.env.PUBLIC_URL}/old/presentations/Jaroslav_Resler+Jan_Geletič+Pavel_Krč.pdf`,
        

    },
    { 
        title: "Data in the city",
        subTitle: "Joao Tremoceiro",
        href: `${process.env.PUBLIC_URL}/old/presentations/Joao_Tremoceiro.pdf`,
       

    },
    { 
        title: "The Data Management Strategy ",
        subTitle: "Maria Jesús Calvo",
        href: `${process.env.PUBLIC_URL}/old/presentations/Maria_Jesús_Calvo.pdf`,
        

    },
    { 
        title: "Empowering a Culture ",
        subTitle: "Michael Woodbridge",
        href: `${process.env.PUBLIC_URL}/old/presentations/Michael_Woodbridge`,
        

    },
    { 
        title: "Network-based mobility Analytics",
        subTitle: "Michal Jakob",
        href: `${process.env.PUBLIC_URL}/old/presentations/Michal_Jakob.pdf`,
        

    },
    { 
        title: "Sharing data & avaliable capacity",
        subTitle: "Ondrej Kratky",
        href: `${process.env.PUBLIC_URL}/old/presentations/Ondrej_Kratky.pdf`,
        

    },
    { 
        title: "Waze & emergency services",
        subTitle: "Peter van der Veen",
        href: `${process.env.PUBLIC_URL}/old/presentations/Peter_van_der_Veen.pdf`,
        

    },
    { 
        title: "MaaS & Open Data",
        subTitle: "Ross Douglas",
        href: `${process.env.PUBLIC_URL}/old/presentations/Ross_Douglas.pdf`,
        
    },
    { 
        title: "Trafic data processing ",
        subTitle: "Vladimír Faltus",
        href: `${process.env.PUBLIC_URL}/old/presentations/Vladimír_Faltus.pdf`,
       

    },
    { 
        title: "Circular Scan in Prague",
        subTitle: "Vojtech Vosecký",
        href: `${process.env.PUBLIC_URL}/old/presentations/Vojtech_Vosecký.pdf`,
        

    },
   
  ];
import { FC, useEffect } from "react";
import styled from "styled-components";

const ModalContainer = styled.div`
  z-index: 50;
  overflow: auto;
  position: fixed;
  inset: 0;
`;

export const Modal: FC = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return <ModalContainer>{children}</ModalContainer>;
};

import { Col } from "components/Col";
import { LanguageSwitch } from "components/Localization/languageSwitch";
import { Row } from "components/Row";
import { FC } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";
import { HeaderLogo } from "./HeaderLogo";
import { InlineNavigation } from "./InlineNavigation";
import { MenuNavigation } from "./MenuNavigation";

const FlexRow = styled(Row)`
  align-items: center;

  ${queries.md} {
    justify-content: end;
    margin-top: 2rem;
  }
`;

const MarginCol = styled(Col)`
  ${queries.md} {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;
const LanguageButton = styled.div`
  margin-top: 5px;
  display: none;

  ${queries.md} {
    display: block;
    justify-self: end;
    align-self: end;
  }
`;
export const Header: FC = () => {
  return (
    <header>
      <FlexRow>
        <Col startSM={2} endSM={11} endMD={10}>
          <InlineNavigation />
          <MenuNavigation />
        </Col>
        <Col startSM={10} endSM={11}>
          <LanguageButton>
            <LanguageSwitch />
          </LanguageButton>
        </Col>
      </FlexRow>
      <FlexRow>
        <MarginCol startSM={1} endSM={11}>
          <HeaderLogo />
        </MarginCol>
      </FlexRow>
    </header>
  );
};

import { TextContainer } from "components/TextContainer";
import { TintedImage } from "components/TintedImage";
import { FC } from "react";
import LazyLoad from "react-lazy-load";
import styled from "styled-components";
import { Quote } from "types/quote";
import { queries } from "utils/queries";

const AuthorContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  display: flex;

  ${queries.md} {
    margin-bottom: 112px;
  }
`;

const Avatar = styled(TintedImage)`
  margin-right: 16px;
  flex: 0 0 96px;
`;

const AuthorName = styled.p`
  font-size: 16px;
  line-height: 150%;
  font-weight: bold;
  color: var(--white-color);
  margin-bottom: 8px;
`;

const AuthorTitle = styled.p`
  font-size: 12px;
  line-height: 150%;
  color: var(--white-color);

  ${queries.md} {
    font-size: 16px;
    line-height: 150%;
  }
`;

const AutorSection = styled.div`
  flex: 1 1 auto;
`;

export const FeedbackItem: FC<{ quote: Quote }> = ({ quote }) => {
  return (
    <div>
      <TextContainer>
        <p>{quote.quote}</p>
      </TextContainer>
      <AuthorContainer>
        {!!quote.speaker.picture && (
          <LazyLoad>
            <Avatar
              width={96}
              height={96}
              src={quote.speaker.picture.url}
              alt={quote.speaker.name}
            />
          </LazyLoad>
        )}
        <AutorSection>
          <AuthorName>{quote.speaker.name}</AuthorName>
          <AuthorTitle>{quote.speaker.title}</AuthorTitle>
        </AutorSection>
      </AuthorContainer>
    </div>
  );
};

import styled from "styled-components";
import { queries } from "utils/queries";

export const Col = styled.div<{
  startSM?: number;
  endSM?: number;
  startMD?: number;
  endMD?: number;
  startLG?: number;
  endLG?: number;
}>`
  grid-column-start: ${({ startSM }) => (startSM !== undefined ? startSM + 1 : 2)};
  grid-column-end: ${({ endSM }) => (endSM !== undefined ? endSM + 1 : 12)};

  ${queries.md} {
    ${({ startMD }) => (startMD !== undefined ? `grid-column-start: ${startMD + 1};` : "")}
    ${({ endMD }) => (endMD !== undefined ? `grid-column-end: ${endMD + 1};` : "")}
  }

  ${queries.lg} {
    ${({ startLG }) => (startLG !== undefined ? `grid-column-start: ${startLG + 1};` : "")}
    ${({ endLG }) => (endLG !== undefined ? `grid-column-end: ${endLG + 1};` : "")}
  }

  /* temp */
  /* outline: 1px solid red; */
`;

export const dataInnovation = [
    { url: "https://www.youtube.com/embed/v4bG91jUjPM" },
    { url: "https://www.youtube.com/embed/K9yB044ymII" },
    { url: "https://www.youtube.com/embed/CzlarGkU3gw" },
];

export const openSource = [
    { url: "https://www.youtube.com/embed/bfjLae1YyiA" },
    { url: "https://www.youtube.com/embed/sL_xPSGlr9Q" },
    { url: "https://www.youtube.com/embed/qwn450Qz65E" },
    { url: "https://www.youtube.com/embed/f7f5xnUQfSY" },
];

export const circularCity = [
    { url: "https://www.youtube.com/embed/GCZooyAoGJE" },
    { url: "https://www.youtube.com/embed/X-V2tAE2amA" },
    { url: "https://www.youtube.com/embed/BaL09Z08gVk" },
    { url: "https://www.youtube.com/embed/bML49-hWoa4" },
];

import { Col } from "components/Col";
import { GradientA } from "components/GradientLink";
import { LanguageSwitch } from "components/Localization/languageSwitch";
import { Modal } from "components/Modal";
import { Row } from "components/Row";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { queries } from "utils/queries";
import { links } from "./links";

const Container = styled.div`
  text-align: right;
`;

const BurgerButton = styled.button`
  border: none;
  background: none;
  padding: 8px;

  ${queries.md} {
    display: none;
  }
`;

const CloseButton = styled(BurgerButton)`
  color: var(--white-color);
  font-size: 48px;
  width: 48px;
`;

const BurgerLine = styled.div`
  width: 30px;
  height: 3px;
  background: var(--white-color);
  border-radius: 32px;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const MenuContainer = styled.div`
  padding-top: 32px;
  width: 100%;
  min-height: 100vh;
  background-color: var(--secondary-color);
`;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;

  & a {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
    display: inline-block;
  }
`;
const LanguageButton = styled(LanguageSwitch)`
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 32px;
  display: inline-block;
`;

const ColAlignRight = styled(Col)``;

export const MenuNavigation: FC = () => {
  const langFromStorage = localStorage.getItem("lang");
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <Container>
        <BurgerButton type="button" onClick={toggleMenu}>
          <BurgerLine />
          <BurgerLine />
          <BurgerLine />
        </BurgerButton>
      </Container>
      {showMenu && (
        <Modal>
          <MenuContainer>
            <Row>
              <ColAlignRight startSM={1} endSM={11}>
                <CloseButton type="button" onClick={toggleMenu}>
                  &times;
                </CloseButton>
              </ColAlignRight>
              <Col startSM={1} endSM={11}>
                <List>
                  {links(langFromStorage).map(
                    ({ label, link, external }, index) => (
                      <li key={index} onClick={() => setShowMenu(false)}>
                        <GradientA
                          href={link}
                          target={external ? "_blank" : undefined}
                          rel={external ? "noreferrer" : undefined}
                        >
                          {t(`menu:${label}`)}
                        </GradientA>
                      </li>
                    )
                  )}
                  <LanguageButton />
                </List>
              </Col>
            </Row>
          </MenuContainer>
        </Modal>
      )}
    </>
  );
};

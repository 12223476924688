import { useFormikContext } from "formik";
import { useMemo } from "react";
import styled from "styled-components";
import { FilterKeysByValueType } from "types/filterKeysByValueType";
import { InputProps } from "types/InputProps";

const StyledInput = styled.input`
  width: 40px;
  height: 40px;
  margin-right: 24px;
`;

export type CheckboxProps<FormData> = InputProps<string & FilterKeysByValueType<FormData, boolean>>;

export const useCheckbox = <FormData extends {}>({ name }: CheckboxProps<FormData>) => {
  const { handleBlur, handleChange, values, validationSchema, errors, touched } =
    useFormikContext<FormData>();

  const required = validationSchema?.fields?.[name]?.exclusiveTests?.required === true;
  const isTouched = touched?.[name];
  const errorMessage = errors?.[name];
  const value = values[name] as unknown as boolean;
  const id = `checkbox-${name}`;

  const checkboxElement = useMemo(
    () => (
      <StyledInput
        type="checkbox"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        checked={value}
        id={id}
      />
    ),
    [required, value, id, handleBlur, handleChange, name]
  );

  const checkboxData = useMemo(
    () => ({
      checkboxElement,
      isTouched,
      errorMessage,
      id,
    }),
    [checkboxElement, errorMessage, id, isTouched]
  );

  return checkboxData;
};

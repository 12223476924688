import styled from "styled-components";

export const InputContainer = styled.div`
  border-bottom: 1px solid #000;
  margin-bottom: 32px;

  &:focus-within {
    border-bottom: 1px solid var(--white-color);
  }
`;

import { ComponentPropsWithoutRef, FC } from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  height: 65%;
  margin-bottom: 2.8em;
`;

const StyledImage = styled.img<{ fullWidth?: boolean }>`
  width: 100%;
  height: 100%;

  object-fit: cover;
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
  filter: grayscale(1);
  display: block;
`;

const ImageOverlayColor = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(289.11deg, #0000ff -0.65%, rgba(0, 0, 255, 0) 50.36%);
  width: 100%;
  height: 100%;
`;

export const TintedImage: FC<
  Omit<ComponentPropsWithoutRef<"img">, "children"> & { fullWidth?: boolean }
> = ({ className, ...props }) => {
  return (
    <ImageWrapper className={className}>
      <StyledImage {...props} />
      <ImageOverlayColor />
    </ImageWrapper>
  );
};

import { Fragment, useState } from "react";
import styled from "styled-components";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import { TextContainer } from "components/TextContainer";
import { Tabs } from "components/Tabs";
import { Topic, TopicButton } from "./Topic";
import { queries } from "utils/queries";
import { RightWave1 } from "components/Waves";
import { IAboutComponent, ISliderComponent } from "types/mainPageType";
import { ReactComponent as ClimateChangeIcon } from "./climate_change.svg";
import { ReactComponent as MobilityIconIcon } from "./mobility.svg";
import { ReactComponent as PublicSpaceIcon } from "./public_space.svg";
import { GradientA } from "components/GradientLink";
import ReactMarkdown from "react-markdown";
import LazyLoad from "react-lazy-load"


export const iconsSliderArr = [
  {
    icon: ClimateChangeIcon,
  },
  {
    icon: MobilityIconIcon,
  },
  {
    icon: PublicSpaceIcon,
  },
];
const SpanBlock = styled.span`
  display: block;
`;
const MarginRow = styled(Row)`
  margin-top: 128px;
  color: var(--black-color);
`;
const List = styled.ul`
  list-style: none;

  ${queries.onlySM} {
    display: none;
  }
`;
const TopicOnlySM = styled(Topic)`
  ${queries.md} {
    & {
      display: none;
    }
    & svg {
      fill: var(--white-color);
    }
  }
`;
const Image = styled.img`
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;
const TitleWithMargin = styled.h2`
  ${queries.onlySM} {
    margin-bottom: 32px;
  }
`;
const StyledTextContainer = styled(TextContainer)`
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  color: var(--black-color);
  a{
    color: var(--black-color);
    text-decoration: underline 
  }
  a:hover ,
  a:hover > *,
  a:hover  {
    color: var(--primary-color);
    background: transparent;
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: text;
    background-clip: text;
    fill: var(--primary-color);
  }
`;
const ImageMask = styled.div`
  display: inline-block;
  background: linear-gradient(
    283.18deg,
    #0000ff 0.57%,
    rgba(0, 0, 255, 0) 92.03%
  );
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 36.25%;
  position: relative;
  ${queries.md} {
    margin-bottom: 64px;
  }
  ${queries.onlySM} {
    margin-bottom: 24px;
  }

`;
const ImgBlock = styled.div`
  display: block;
`;
const GradientMailto = styled(GradientA)`
  color: var(--black-color);
  text-decoration: underline 
`

export const About = (props: {
  aboutComponentData: IAboutComponent;
  sliderComponentData: ISliderComponent;
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { aboutComponentData, sliderComponentData } = props;
  const titleAboutArr = aboutComponentData.titleAbout.split(" ", 2);
  const PCDCMailLink = () => (
    <GradientMailto href="mailto:pcdc@operatorict.cz">pcdc@operatorict.cz</GradientMailto>
  );
  // const link = "/#registration";
  // const { pathname } = useLocation();

  // const onNavLinkClick = useCallback(
  //   (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
  //     return onNavLinkClickCallback(e, link, pathname);
  //   },
  //   [pathname]
  // );
  return (
    <RelativeSection id="about" paddingMD={192} paddingLG={160}>
      <RightWave1 />
      <Row>
        {/* <RightWave1 /> */}
        <Col startMD={1} endMD={5}>
          <TitleWithMargin>
            <SpanBlock>{titleAboutArr[0]}</SpanBlock>
            <SpanBlock>
              {aboutComponentData.titleAbout.substring(
                titleAboutArr[0].length + 1
              )}
            </SpanBlock>
          </TitleWithMargin>
        </Col>

        <Col startMD={5} endMD={11}>
          <StyledTextContainer>
            <p>
              {aboutComponentData.aboutParagraph[0] &&
                aboutComponentData.aboutParagraph[0].text}
            </p>
            <p>
              {aboutComponentData.aboutParagraph[1] &&
                aboutComponentData.aboutParagraph[1].text}
            </p>

            <p >
              <ReactMarkdown>
                {
                  aboutComponentData.aboutParagraph[3] &&
                  aboutComponentData.aboutParagraph[3].text
                }
              </ReactMarkdown>
            </p>
            <p style={{ fontWeight: "bold" }}>
              {aboutComponentData.aboutParagraph[2] &&
                aboutComponentData.aboutParagraph[2].text}
              <PCDCMailLink />
            </p>
          </StyledTextContainer>
        </Col>
      </Row>
      <MarginRow>
        <Col startMD={1} endMD={4} endLG={3}>
          <List>
            {sliderComponentData.sliderItems.map(
              ({ sliderItemTitle }, sliderId) => (
                <li key={sliderId}>
                  <TopicButton
                    Icon={iconsSliderArr[sliderId].icon}
                    onClick={setSelectedTabIndex}
                    isActive={selectedTabIndex === sliderId}
                    topicIndex={sliderId}
                  >
                    {sliderItemTitle}
                  </TopicButton>
                </li>
              )
            )}
          </List>
        </Col>
        <Col startMD={5} endMD={11}>
          <Tabs
            selectedTabIndex={selectedTabIndex}
            tabs={sliderComponentData.sliderItems.map(
              ({ sliderItemTitle, sliderItemContentArr, image }, sliderId) => (
                <Fragment key={sliderId}>
                  <TopicOnlySM Icon={iconsSliderArr[sliderId].icon}>
                    {sliderItemTitle}
                  </TopicOnlySM>
                  <ImgBlock>
                    <ImageMask className="pickgradient">
                      <LazyLoad>
                        <Image src={image} alt={sliderItemTitle} />
                      </LazyLoad>
                    </ImageMask>
                  </ImgBlock>
                  <TextContainer>
                    {sliderItemContentArr.map((text, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </TextContainer>
                </Fragment>
              )
            )}
          />
        </Col>
      </MarginRow>
    </RelativeSection>
  );
};

import styled from "styled-components";
import { queries } from "utils/queries";

export const Section = styled.section<{
  paddingMD?: number;
  paddingLG?: number;
}>`
  padding-top: 64px;
  padding-bottom: 64px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 128)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 128)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 192)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 192)}px;
  }
`;

export const SectionWithBG = styled(Section)`
  background: var( --primary-color);
`;

export const RelativeSection = styled(Section)`
  position: relative;
`;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import czText from "./cz/translation.json";
import enText from "./en/translation.json";

const resources = {
  cz: czText,
  en: enText,
};

const storedLng = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
  resources,
  lng: storedLng && ["cz", "en"].includes(storedLng) ? storedLng : "en",
  //debug: config.isDev,
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18n: "languageChanged",
  },
});

export default i18n;

export const configuration = {
  API_URL: window.ENV.API_URL,
  API_KEY: window.ENV.API_KEY,
  GOOGLE_ANALYTICS_ID: window.ENV.GOOGLE_ANALYTICS_ID,
  GOOGLE_ANALYTICS_ID_COOKIELESS: window.ENV.GOOGLE_ANALYTICS_ID_COOKIELESS,
  CURRENT_YEAR: window.ENV.CURRENT_YEAR || 2021,
  SHOW_REGISTRATION: window.ENV.SHOW_REGISTRATION || false,
  PRESENTATIONS_URL:
    "https://drive.google.com/drive/folders/1Y8VNE-gE4FN1g-2kv9eZXGF3lIpfcZqs",
  URL_2021: window.ENV.URL_2021,
  API_URL_IMG: window.ENV.API_URL_IMG,
  GTM_ID: window.ENV.GTM_ID,
};

import styled from "styled-components";

export const UnderlinedText = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  text-decoration: underline;

  a:hover & {
    color: rgba(196, 196, 196, 0.5);
    fill: var(--white-color);
    cursor: pointer;
  }
  &:hover svg {
    fill: var(--white-color);
    cursor: pointer;
    color: rgba(196, 196, 196, 0.5);
  }
`;

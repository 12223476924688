import { FC } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";
import { NavLinks } from "components/NavLinks";
import { configuration } from "configuration";
import { useTranslation } from "react-i18next";

const StyledSpan = styled.span`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 32px;

  ${queries.onlySM} {
    margin-bottom: 16px;
    font-size: 14px;
  }
`;

export const FooterPreviousYears: FC = () => {
  const { t } = useTranslation();
  return (
    <StyledSpan>
      <span>{t(`footerTitle:prevyears`)}</span>
      <NavLinks margin={24}>
        <li>
          <GradientA href={`${configuration.URL_2021}`} rel="noreferrer">
            2021
          </GradientA>
        </li>
        <li>
          <GradientA href="/old/2020" rel="noreferrer">
            2020
          </GradientA>
        </li>
        <li>
          <GradientA href="/old/2019" rel="noreferrer">
            2019
          </GradientA>
        </li>
      </NavLinks>
    </StyledSpan>
  );
};

export default function dataProgramCz() {
  return (
    <div>
      <p>
        V současné době dáváme dohromady program na rok 2022 a zveřejníme jej,
        až bude připraven. Už teď ale můžeme oznámit, že letošní ročník nabídne
        program širokému publiku, které se bude moci zúčastnit různých typů
        aktivit, které svou formou budou připomínat festival, spíše než
        konferenci.
      </p>
      <br />
      <p>
        Během dne se budou konat <b> workshopy </b> pro technologicky
        orientované návštěvníky, dále naši zahraniční hosté představí,{" "}
        <b>lekce s případovými studiemi</b>představitelům měst a jejich
        spolupracovníkům, kdokoliv se pak bude moci přidat ke{" "}
        <b>komentovaným procházkám po městě.</b> Ve večerních hodinách otevřeme
        kongres pro širokou veřejnost, která se bude moci zúčastnit hlavních{" "}
        <b>přednášek </b>, našich hostů a přidat se na následující afterparty..
      </p>
      <br />
      <p>
        Každá z večerních prezentací nabídne shrnutí témat, která se budou
        detailně probírat během procházek, workshopů a interaktivních lekcí.
        Denní program nabídne možnost místním politikům, úředníkům, a
        technologickým nadšencům, aby navázali kontakty se svými mezinárodními
        kolegy.
      </p>
    </div>
  );
}

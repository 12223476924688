import { configuration } from "configuration";
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Program } from "types/programType";
import programCs from "../data/cs/program.json";
import programEn from "../data/program.json";

interface ContextValue {
  programs: Program[] | null;
  isLoading: boolean;
  hasFailed: boolean;
}

const ProgramContext = createContext<ContextValue>({
  programs: null,
  isLoading: false,
  hasFailed: false,
});

// const sortTopics = (topicA: Topic, topicB: Topic) => {
//   if (topicA.timeFrom < topicB.timeFrom) {
//     return -1;
//   } else if (topicA.timeFrom > topicB.timeFrom) {
//     return 1;
//   } else {
//     if (topicA.timeTo < topicB.timeTo) {
//       return -1;
//     } else if (topicA.timeTo > topicB.timeTo) {
//       return 1;
//     } else {
//       return 0;
//     }
//   }
// };

export const ProgramsProvider: FC = ({ children }) => {
  const [programs, setPrograms] = useState<Program[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed] = useState(false);

  useEffect(() => {
    const langFromStorage = localStorage.getItem("lang");
    setIsLoading(true);
    const programData = langFromStorage === "en" ? programEn : programCs;
    const transformedProgramArr = programData.map((program: any) => {
      return {
        year: program.attributes.year,
        date: program.attributes.date,
        topic: {
          id: program.id,
          slug: program.attributes.slug,
          title: program.attributes.title,
          speakers: program.attributes.speakers.data.map((speaker: any) => {
            return {
              name: speaker.attributes.name,
              title: speaker.attributes.title,
              company: speaker.attributes.company,
              companyUrl: speaker.attributes.companyUrl,
              year: speaker.attributes.year,
              description: speaker.attributes.description,
              picture: {
                url: speaker.attributes.picture.data.attributes.url,
              },
              id: speaker.id,
              slug: speaker.attributes.slug,
            };
          }),
          timeFrom: program.attributes.timeFrom,
          timeTo: program.attributes.timeTo,
          description: program.attributes.description,
          type: program.attributes.type,
          event_topic_tags: program.attributes.topic_tags.data.map(
              (topicObj: any) => {
                return { tag: topicObj.attributes.tag };
              }
          ),
        },
      };
    });
    transformedProgramArr.sort((a: any, b: any) => (a.date > b.date ? 1 : -1))
    const groupTopicsByDays = (
        transformedProgramArr: any
    ): Program["eventDays"] => {
      const groups = transformedProgramArr.reduce(
          (groups: any, item: any) => ({
            ...groups,
            [item.date]: [...(groups[item.date] || []), item],
          }),
          {}
      );

      let resultArr: Program["eventDays"] = [];
      Object.keys(groups).forEach((dateValue: any) => {
        resultArr.push({
          topics: groups[dateValue].map((el: any) => {
            return el.topic;
          }),
          date: dateValue,
        });
      });
      return resultArr;
    };

    const sortedPrograms: Program[] = [
      {
        eventDays: groupTopicsByDays(transformedProgramArr),
        year: configuration.CURRENT_YEAR,
      },
    ];
    setPrograms(sortedPrograms);
    setIsLoading(false);
  }, []);

  const contextValue = useMemo(() => {
    return {
      programs,
      isLoading,
      hasFailed,
    };
  }, [programs, isLoading, hasFailed]);


  return (
    <ProgramContext.Provider value={contextValue}>
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgramsData = () => {
  return useContext(ProgramContext);
};

import { useCallback, useEffect, useRef, useState } from "react";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import styled from "styled-components";
import { TextInput } from "components/TextInput";
import { Formik } from "formik";
import { PrimaryButton } from "components/Buttons";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { CountryPicker } from "components/CountryPicker";
import { SelectInputRegisterAs } from "components/SelectInputRegisterAs";
import { AgreementCheckbox } from "./AgreementCheckbox";
import { registrationValidationSchema } from "./registrationValidationSchema";
import {
  organizationTypeVisitorOptions,
  organizatoinTypePressOptions,
  RegisterAsOption,
  registerAsOptions,
  AttendanceOption,
} from "./registrationOptions";
import { ErrorMessage } from "components/ErrorMessage";
import { configuration } from "configuration";
import { TextContainer } from "components/TextContainer";
import { IMainPage } from "types/mainPageType";
import { useTranslation } from "react-i18next";
import { RightWave2 } from "components/Waves";

const Title = styled.h2`
  margin-bottom: 40px;
`;

interface FormData {
  name: string;
  surname: string;
  email: string;
  country: string;
  attendInPerson: "" | AttendanceOption;
  address: string;
  phoneNumber: string;
  registerAs: "" | RegisterAsOption;
  organizationName: string;
  organizationType: string;
  project: string;
  countryOfRealization: string;
  cityOfRealization: string;
  description: string;
  agreement: boolean;
  position: string;
  funFact: string;
  language: string;
}

type PostData = Omit<FormData, "attendInPerson"> & { attendInPerson: boolean };

const initialValues: FormData = {
  name: "",
  surname: "",
  email: "",
  country: "",
  attendInPerson: "",
  address: "",
  phoneNumber: "",
  registerAs: "",
  organizationName: "",
  organizationType: "",
  project: "",
  countryOfRealization: "",
  cityOfRealization: "",
  description: "",
  agreement: false,
  position: "",
  funFact: "",
  language: "",
};

const getDataToSend = (data: FormData) => {
  const propertiesToSend: Array<keyof FormData> = [
    "name",
    "surname",
    "email",
    "country",
    "registerAs",
    "position",
    "funFact",
    "language",
  ];

  data.language = localStorage.getItem("lang") === "en" ? "en" : "cz";

  if (data.attendInPerson === "In person") {
    propertiesToSend.push("address");
  }

  if (data.attendInPerson === "In person" || data.registerAs === "Speaker") {
    propertiesToSend.push("phoneNumber");
  }

  if (data.registerAs === "Visitor" || data.registerAs === "Press") {
    propertiesToSend.push("organizationName");
    propertiesToSend.push("organizationType");
  }

  if (data.registerAs === "Speaker") {
    propertiesToSend.push("project");
    propertiesToSend.push("cityOfRealization");
    propertiesToSend.push("countryOfRealization");
    propertiesToSend.push("description");
  }

  let dataToSend: Partial<PostData> = {
    attendInPerson: data.attendInPerson === "In person",
  };
  propertiesToSend.forEach((property) => {
    dataToSend = { ...dataToSend, [property]: data[property] };
  });

  return dataToSend;
};

const registrationUrl = `${configuration.API_URL}/api/registered-users`;

export const Registration = (props: { ragistrationTitle: IMainPage }) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [hasFailed, setFailed] = useState(false);
  const [isSent, setSent] = useState(false);
  const anchor = useRef<HTMLDivElement>(null);
  const { ragistrationTitle } = props;
  const handleSubmit = useCallback((formData: FormData) => {
    const userData = getDataToSend(formData);

    setFailed(false);
    setLoading(true);

    fetch(registrationUrl, {
      method: "POST",
      body: JSON.stringify({ data: userData }),
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${configuration.API_KEY}`,
      },
    })
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        setFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isSent && anchor.current) {
      anchor.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isSent]);

  const optionsGenerated = registerAsOptions.map((el) => {
    return { value: el, title: t(`registersOptions:${el}`) };
  });
  const optionsPressGenerated = organizatoinTypePressOptions.map((el) => {
    return { value: el, title: t(`OrganizationTypeAsPress:${el}`) };
  });
  const optionVisitorGenerated = organizationTypeVisitorOptions.map((el) => {
    return { value: el, title: t(`OrganizationTypeAsVisitor:${el}`) };
  });

  return (
    <RelativeSection id="registration" ref={anchor}>
      <RightWave2 />
      <Row>
        <Col startMD={1} endMD={4} endLG={3}>
          <Title>{ragistrationTitle.registrationCmponentTitle}</Title>
          {/* <Title>Register &amp; take your seat</Title> */}
        </Col>
        <Col startMD={5} endMD={11}>
          {!isSent && (
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={registrationValidationSchema}
            >
              {({ handleSubmit, values }) => (
                <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                  <TextInput<FormData> label={t(`registration:Name`)} name="name" />
                  <TextInput<FormData> label={t(`registration:Surname`)} name="surname" />
                  <TextInput<FormData> label="E-mail" type="email" name="email" />
                  <CountryPicker<FormData>
                    label={t(`registration:Choosecountry`)}
                    name="country"
                  />

                  {/* <SelectInput<FormData>
                    label={t(`registration:Chooseattendance`)}
                    name="attendInPerson"
                    options={attendanceOptions}
                  /> */}

                  <SelectInputRegisterAs<FormData>
                    label={t(`registration:registeras`)}
                    name="registerAs"
                    options={optionsGenerated}
                  />

                  {values.attendInPerson === "In person" && (
                    <TextInput<FormData> label="Address" name="address" />
                  )}
                  {(values.attendInPerson === "In person" ||
                    values.registerAs === "Speaker") && (
                    <TextInput<FormData>
                      label={t(`registerAs:organizationName`)}
                      name="phoneNumber"
                    />
                  )}

                  {(values.registerAs === "Visitor" || values.registerAs === "Press") && (
                    <>
                      <TextInput<FormData>
                        label={t(`registerAs:organizationName`)}
                        name="organizationName"
                      />
                      <SelectInputRegisterAs<FormData>
                        label={t(`registerAs:organizationType`)}
                        name="organizationType"
                        options={
                          values.registerAs === "Press"
                            ? optionsPressGenerated
                            : optionVisitorGenerated
                        }
                      />
                    </>
                  )}

                  {values.registerAs === "Speaker" && (
                    <TextInput<FormData> label={t(`registerAs:project`)} name="project" />
                  )}

                  {values.registerAs !== "" && (
                    <TextInput<FormData>
                      label={t(`registerAs:position`)}
                      name="position"
                    />
                  )}

                  {values.registerAs === "Speaker" && (
                    <>
                      <TextInput<FormData>
                        label={t(`registerAs:countryOfRealization`)}
                        name="countryOfRealization"
                      />
                      <TextInput<FormData>
                        label={t(`registerAs:cityOfRealization`)}
                        name="cityOfRealization"
                      />
                      <TextInput<FormData>
                        label={t(`registerAs:description`)}
                        name="description"
                      />
                    </>
                  )}

                  {/* <TextInput<FormData>
                    label={t(`registration:funfuct`)}
                    name="funFact"
                  /> */}

                  <AgreementCheckbox<FormData>
                    name="agreement"
                    attendInPerson={values.attendInPerson === "In person"}
                  />

                  <PrimaryButton disabled={isLoading} type="submit">
                    <TextWithArrowIcon>
                      {t(`registration:bookyourseat`)}
                    </TextWithArrowIcon>
                    {hasFailed && <ErrorMessage>Registration failed :(</ErrorMessage>}
                  </PrimaryButton>
                </form>
              )}
            </Formik>
          )}
          {isSent && (
            <TextContainer id="completedRegistration">
              <p>{t(`registration:successRegister`)}</p>
            </TextContainer>
          )}
        </Col>
      </Row>
    </RelativeSection>
  );
};

import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/Section";
import { Row } from "components/Row";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import Iframe from 'react-iframe'
import { dataNarratives, designAccessibility, newExpressions } from "./data";
import { useTranslation } from "react-i18next";


const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
`;

const SpanBlock = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  color: var(--primary-color);
`;

const VideoSubTitle = styled.p`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 2rem;
  line-height: 125%;
  color: #fff;
  position: absolute;

  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;
const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  ${queries.onlySM} {
    padding-bottom: 20px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding-top: 3rem;
  width: 100%;
`;

const MarginRow = styled(Row)`
  ${queries.lg} {
    margin-top: 2.5rem;
  }

  ${queries.onlySM} {
    padding-top: 10px;
    justify-content: center;
  }
`;

const IframeStyle = styled(Iframe)`
    width: 100%;
    height: 25em;
    display: block;
    position: relative;
    border:none;
    border-radius:5px;
    ${queries.md} {
      margin-top: 2.5rem;
    }
    ${queries.onlySM} {
      height: 25em;
      
    }
    ${queries.xs}{
      height: 15em;
 
`
export const ArchiveVideo: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container id="video">
        <SpanBlock>{t(`archive:Videos`)}</SpanBlock>
        <VideoSubTitle>Cities Talk</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            <Col startSM={0} endSM={12} startMD={0} endMD={12}>
              <Wrapper>
                <Iframe url="https://www.youtube.com/embed/xoAYPW8DmiM?start=1"
                  width="100%"
                  styles={{ height: "45rem" }}
                  id=""
                  frameBorder={0}
                  className="videointro"
                  position="relative"
                  loading='lazy'
                  scrolling="no"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Wrapper>
            </Col>
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-innovation">
        <VideoSubTitle>New expressions</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            {newExpressions.map(({ url }, index) => (
              <Col key={index}
                startMD={index % 2 === 0 || index === 0 ? 0 : 6}
                endMD={index % 2 === 0 || index === 0 ? 6 : 12}
                startSM={index % 2 === 0 || index === 0 ? 0 : 0}
                endSM={index % 2 === 0 || index === 0 ? 12 : 12}>
                <Wrapper>
                  <IframeStyle url={url}
                    width="100%"
                    styles={{ height: "25rem" }}
                    id=""
                    frameBorder={0}
                    className="videointro"
                    position="relative"
                    loading='lazy'
                    scrolling="no"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Wrapper>
              </Col>
            ))}
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-opensource">
        <VideoSubTitle>Design accessibility</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            {designAccessibility.map(({ url }, index) => (
              <Col startMD={index % 2 === 0 || index === 0 ? 0 : 6}
                endMD={index % 2 === 0 || index === 0 ? 6 : 12}
                startSM={index % 2 === 0 || index === 0 ? 0 : 0}
                endSM={index % 2 === 0 || index === 0 ? 12 : 12}>
                <Wrapper>
                  <IframeStyle url={url}
                    width="100%"
                    styles={{ height: "25rem" }}
                    id=""
                    frameBorder={0}
                    className="videointro"
                    position="relative"
                    loading='lazy'
                    scrolling="no"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Wrapper>
              </Col>
            ))}
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="video-opensource">
        <VideoSubTitle>Data narratives</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            {dataNarratives.map(({ url }, index) => (
              <Col startMD={index % 2 === 0 || index === 0 ? 0 : 6}
                endMD={index % 2 === 0 || index === 0 ? 6 : 12}
                startSM={index % 2 === 0 || index === 0 ? 0 : 0}
                endSM={index % 2 === 0 || index === 0 ? 12 : 12}>
                <Wrapper>
                  <IframeStyle url={url}
                    width="100%"
                    styles={{ height: "25rem" }}
                    id=""
                    frameBorder={0}
                    className="videointro"
                    position="relative"
                    loading='lazy'
                    scrolling="no"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Wrapper>
              </Col>
            ))}
          </MarginRow>
        </VideoBox>
      </Container>
    </>
  );
};

import styled from "styled-components";

export const UnderlinedLinkWrapper = styled.p`
  font-family: "Montserrat", sans-serif;

  & a {
    text-decoration: underline;
  }

  & a:hover {
    color: linear-gradient(289.11deg, #0000FF -0.65%, rgba(0, 0, 255, 0) 80.36%);
  }
`;

import styled from "styled-components";
import { queries } from "utils/queries";

export const Row = styled.div`
  display: inline-grid;
  gap: 0px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  ${queries.md} {
    gap: 32px;
  }
  ${queries.onlySM} {
    gap: 16px;
  }
`;

import { FC } from "react";
import { Row } from "components/Row";
import styled from "styled-components";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import { Section } from "components/Section";
import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { useTranslation } from "react-i18next";

const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
  ${queries.onlySM} {
  }
`;

const Margin = styled.div`
  margin-top: 2rem;
  ${queries.onlySM} {
    margin-top: 1rem;
  }
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
 
  & svg {
    fill: var(--primary-color);
  }
  &:hover svg {
    fill: #fff;
  }
`;

const TextWithArrow = styled(TextWithArrowIcon)`
background: none;
  border: none;
  &:hover svg {
    background: none;
    cursor: pointer;
  }
`

const ActiveYear = styled.a`
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height:  133% ;
color: #FFFFFF;
${queries.onlySM} {
  font-size: 20px;
}
`;

const BorderBox = styled.div`
  display: grid;
  width: 100% important!;
  height: 80px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${queries.onlySM} {
    height: 70px;
  }
`;
const TitleBlock = styled.span`
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  color: #ffffff;
  ${queries.md} {
  }
  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;
const SubTitleBlock = styled.span`
font-family: 'Helvetica';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 32px;
`

const TimeBlock = styled.a`
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
line-height: 32px;
color: var(--primary-color);
margin-left: 30px;
${queries.onlySM} {
  margin-left: 5px
}
`
const StyledLinkBox = styled.div`
margin-top: 1.5rem;
margin-left: 32px;
`
export const PreviousYearsLinks: FC = () => {
  const { t } = useTranslation();

  return (

    <Container id="previousyear">
      <TitleBlock> {t(`archive:MoreInformation`)}</TitleBlock>
      <Margin />
      <SubTitleBlock>{t(`archive:Info`)}</SubTitleBlock>
      <Margin />
      <Row>
        <Col startMD={0} endMD={6} >
          <ActiveYear href="/" rel="noreferrer" >
            <BorderBox>
              <StyledLinkBox>
                <Row>
                  <Col startSM={0} endSM={9}>
                    2022  <TimeBlock> 19. –  20. 9. </TimeBlock>
                  </Col>
                  <Col startSM={9} endSM={12}>
                    <ArrowButton>
                      <TextWithArrow size={18} />
                    </ArrowButton>
                  </Col>
                </Row>
              </StyledLinkBox>
            </BorderBox>
          </ActiveYear>
          <Margin />
        </Col>
      </Row>
    </Container >
  );
};

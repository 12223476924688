import { ConditionalWrapper } from "components/ConditionalWrapper";
import { FC, useMemo } from "react";
import styled, { css } from "styled-components";
import { Topic, TopicType } from "types/programType";
import { queries } from "utils/queries";
import { formatTime } from "utils/timeUtils";

const COLORS_PER_TYPE: {
  [k in keyof typeof TopicType]: {
    main: string;
    secondary: string;
    background: string;
    border?: string;
  };
} = {
  [TopicType.normal]: {
    main: "var(--black-color)",
    secondary: "var(--black-color)",
    background: "var(--white-color)",
  },
  [TopicType.afterparty]: {
    main: "var(--black-color)",
    secondary: "var(--primary-color)",
    background: "var(--white-color)",
  },
  [TopicType.break]: {
    main: "var(--black-color)",
    secondary: "var(--primary-color)",
    background: "var(--white-color)",
    border: "var(--white-color)",
  },
  [TopicType.workshop]: {
    main: "var(--black-color)",
    secondary: "var(--black-color)",
    background: "var(--white-color)",
  },
};

const TimeText = styled.p`
  margin-top: 16px;
`;

const TopicTitle = styled.p`
  margin-bottom: 32px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`;

const SpeakerNames = styled.p`
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`;

const CompanyNames = styled.p`
  font-size: 16px;
  line-height: 19px;
`;

const styles = css<{ type: TopicType }>`
  display: block;
  width: 100%;
  padding: 32px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ${({ type }) => {
    const colors = COLORS_PER_TYPE[type];
    return `
    color: ${colors.main};
    background-color: ${colors.background};
    border: ${colors.border ? `2px solid ${colors.border}` : "none"};

    & ${CompanyNames} {
      color: ${colors.secondary};
    }
  `;
  }}
`;

const Container = styled.div<{ type: TopicType }>`
  ${styles}
`;

const ContainerLink = styled.a<{ type: TopicType }>`
  ${styles}
`;

const EventTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EventTag = styled.div<{ isWorkshopTag?: boolean }>`
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 16px;

  color: var(--white-color);
  background: ${({ isWorkshopTag }) => (isWorkshopTag ? "var(--primary-color)" : "var(--primary-color)")};

  &:not(:last-child) {
    margin-right: 16px;
   
  }
  ${queries.onlySM} {
    font-size: 12px;
  }
`;

export const ProgramTopic: FC<{ topic: Topic }> = ({ topic }) => {
  const url = useMemo(() => {
    if (topic.speakers.length === 0 || topic.speakers.length > 1) {
      return `/event/${topic.slug}`;
    } else {
      return `/speaker/${topic.speakers[0].slug}#${topic.slug}`;
    }
  }, [topic]);

  return (
    <ConditionalWrapper
      condition={topic.type !== TopicType.break}
      positiveProps={{ type: topic.type, href: url }}
      PositiveWrapper={ContainerLink}
      NegativeWrapper={Container}
      negativeProps={{ type: topic.type }}
    >
      {(topic.event_topic_tags?.length > 0 ||
        topic.type === TopicType.workshop) && (
          <EventTagContainer>
            {topic.event_topic_tags.map((tag) => (
              <EventTag key={tag.tag}>{tag.tag}</EventTag>
            ))}
            {topic.type === TopicType.workshop && (
              <EventTag isWorkshopTag={true}>Workshop</EventTag>
            )}
          </EventTagContainer>
        )}
      <TopicTitle>{topic.title}</TopicTitle>
      <SpeakerNames>
        {topic.speakers.map((speaker) => speaker.name).join(", ")}
      </SpeakerNames>
      <CompanyNames>
        {topic.speakers.map((speaker) => speaker.company).join(", ")}
      </CompanyNames>
      <TimeText>
        {formatTime(topic.timeFrom)} - {formatTime(topic.timeTo)}
      </TimeText>
    </ConditionalWrapper>
  );
};

import { FC, useEffect } from "react";
import {
  Switch,
  BrowserRouter,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { MainView } from "views/Recent/MainView";
import ReactGA from "react-ga";
import { configuration } from "configuration";
import { SpeakersView } from "views/SpeakersView";
import { EventView } from "views/EventView";
import { ProgramView } from "views/ProgramView";
import { Archive2019 } from "views/Archive/Archive2019";
import { Archive2020 } from "views/Archive/Archive2020";
import { Archive2021 } from "views/Archive/Archive2021";
import { Archive2022 } from "views/Archive/Archive2022";


const gaId = configuration.GOOGLE_ANALYTICS_ID;

if (gaId) {
  ReactGA.initialize(gaId);
}

export const Router: FC = () => {
  return (
    <BrowserRouter>
      <RouterSwitch />
    </BrowserRouter>
  );
};

const RouterSwitch: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (gaId) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path="/" strict={true} >
        <MainView />
      </Route>
      <Route path="/speakers" strict={true} exact={true}>
        <SpeakersView />
      </Route>
      <Route path="/program/:day?" strict={true} exact={true}>
        <ProgramView />
      </Route>
      <Route path="/cz/program/:day?" strict={true} exact={true}>
        <ProgramView />
      </Route>
      <Route path="/archive/2019" strict={true} exact={true}>
        <Archive2019 />
      </Route>
      <Route path="/cz/archive/2019" strict={true} exact={true}>
        <Archive2019 />
      </Route>
      <Route path="/archive/2020" strict={true} exact={true}>
        <Archive2020 />
      </Route>
      <Route path="/cz/archive/2020" strict={true} exact={true}>
        <Archive2020 />
      </Route>
      <Route path="/archive/2021" strict={true} exact={true}>
        <Archive2021 />
      </Route>
      <Route path="/cz/archive/2021" strict={true} exact={true}>
        <Archive2021 />
      </Route>
      <Route path="/archive/2022" strict={true} exact={true}>
        <Archive2022 />
      </Route>
      <Route path="/cz/archive/2022" strict={true} exact={true}>
        <Archive2022 />
      </Route>
      <Route path="/event/:eventId" strict={true} exact={true}>
        <EventView />
      </Route>
      <Route path="/cz/event/:eventId" strict={true} exact={true}>
        <EventView />
      </Route>
      <Route path="/speaker/:speakerId" strict={true} exact={true}>
        <EventView />
      </Route>
      <Route path="/cz/speaker/:speakerId" strict={true} exact={true}>
        <EventView />
      </Route>
      <Route path="/*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

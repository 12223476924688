import { FC } from "react";
import styled from "styled-components";

const Copyright = styled.p`
  font-size: 16px;
  line-height: 32px;
`;

export const FooterCopyright: FC = () => {
  return <Copyright>{new Date().getFullYear()} &copy; Powered by Operátor ICT</Copyright>;
};

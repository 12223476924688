import { useCallback, useEffect, useState } from "react";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import { SmallerTitle } from "components/SmallerTitle";
import styled from "styled-components";
import { Quote } from "types/quote";
import { FeedbackItem } from "./FeedbackItem";
import { ArrowIcon } from "components/ArrowIcon";
import { RightWave2 } from "components/Waves";
import { queries } from "utils/queries";
import feedbackCs from "../../../../data/cs/feedback.json";
import feedbackEn from "../../../../data/feedback.json";

const FeedbackSection = styled(RelativeSection)`
  overflow: hidden;
`;

const GradientBG = styled.div`
  background: var(--primary-color);
  z-index: -2;
  position: absolute;
  inset: 0;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;

  & svg {
    fill: #7788c3;
  }

  &:hover svg {
    fill: var(--white-color);
  }
  ${queries.onlySM} {
    float: right;
  }
`;

const RotatedButton = styled(ArrowButton)`
  margin-right: 32px;

  & svg {
    transform: rotate(180deg);
  }
  ${queries.onlySM} {
    float: left;
  }
`;

export const Feedback = (props: { feedbacksTitleData: string }) => {
  const [feedbacks, setFeedbacks] = useState<Quote[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState(0);
  const [isLoading] = useState(false);
  const [hasFailed] = useState(false);
  const { feedbacksTitleData } = props;

  const selectFeedback = useCallback(
    (feedbackIndex: number) => {
      if (feedbackIndex < 0) {
        setSelectedFeedback(Math.max(feedbacks.length - 1, 0));
      } else if (feedbackIndex >= feedbacks.length) {
        setSelectedFeedback(0);
      } else {
        setSelectedFeedback(feedbackIndex);
      }
    },
    [feedbacks]
  );

  useEffect(() => {
    const langFromStorage = localStorage.getItem("lang");
    const feedbackData = langFromStorage === "en" ? feedbackEn : feedbackCs;
    const feedbacksArr: Quote[] = feedbackData.map((feedback: any) => {
      return {
        speaker: {
          name: feedback.attributes.speaker.data.attributes.name,
          title: feedback.attributes.speaker.data.attributes.title,
          company: feedback.attributes.speaker.data.attributes.company,
          companyUrl:
          feedback.attributes.speaker.data.attributes.companyUrl,
          year: feedback.attributes.speaker.data.attributes.year,
          description:
          feedback.attributes.speaker.data.attributes.description,
          picture: {
            url: feedback.attributes.speaker.data.attributes.picture.data
                .attributes.url,
          },
          id: feedback.attributes.speaker.data.id,
          slug: feedback.attributes.speaker.data.slug,
        },
        quote: feedback.attributes.quote,
        feedbacksTitle: feedback.attributes.feedbacksComponentTitle,
      };
    });
    setFeedbacks(feedbacksArr);
  }, []);

  return (
    <FeedbackSection id="feedback">
      <GradientBG />
      <RightWave2 />
      <Row>
        <Col startMD={1} endMD={3}>
          <SmallerTitle>{feedbacksTitleData}</SmallerTitle>
        </Col>
        <Col startMD={5} endMD={11}>
          {isLoading && <>loading...</>}
          {hasFailed && (
            <>
              There was a problem while downloading feedback, please try again
              later.
            </>
          )}
          {!hasFailed && !isLoading && feedbacks.length > 0 && (
            <>
              <FeedbackItem quote={feedbacks[selectedFeedback]} />
              <RotatedButton
                type="button"
                onClick={() => selectFeedback(selectedFeedback - 1)}
              >
                <ArrowIcon />
              </RotatedButton>
              <ArrowButton
                type="button"
                onClick={() => selectFeedback(selectedFeedback + 1)}
              >
                <ArrowIcon />
              </ArrowButton>
            </>
          )}
        </Col>
      </Row>
    </FeedbackSection>
  );
};

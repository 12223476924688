import { FC } from "react";
import styled from "styled-components";

interface NavLinksProps {
  margin: number;
}

const List = styled.ul<NavLinksProps>`
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline;

  li {
    display: inline;
    margin-left: ${(props) => props.margin}px;
  }
`;

export const NavLinks: FC<Partial<NavLinksProps>> = ({ children, margin = 72 }) => {
  return <List margin={margin}>{children}</List>;
};

import { ArrowIcon } from "components/ArrowIcon";
import { FC } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";

const Container = styled.span`
  display: flex;
  align-items: center;

  button & {
    justify-content: space-between;
  }
`;

const StyledIcon = styled(ArrowIcon)`
  margin-left: 24px;
  fill:var(--white-color);
  &:hover,
  &:hover > *,
  &:hover svg {
    cursor: pointer;
  }

  ${queries.onlySM} {
    button & {
      margin-left: 0;
    }
  }
`;

export const TextWithArrowIcon: FC<{ size?: number }> = ({
  size = 24,
  children,
}) => {
  return (
    <Container>
      {children}
      <StyledIcon height={size} />
    </Container>
  );
};

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const styles = css`
  &:hover,
  &:hover > *,
  &:hover svg {
    background: linear-gradient(
      1.6deg,
      #0000ff 1.36%,
      rgba(0, 0, 255, 0) 98.66%
    );
    color: var(--primary-color);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: text;
    background-clip: text;
    fill: var(--primary-color);
    cursor: pointer;
  }
`;

export const GradientA = styled.a`
  ${styles}
`;

export const GradientLink = styled(Link)`
  ${styles}
`;

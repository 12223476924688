import { FC } from "react";
import styled from "styled-components";

const ErrorMessageContainer = styled.div`
  min-height: 32px;
`;

export const ErrorMessageText = styled.span`
  color: var(--error-color);
  line-height: 32px;
`;

export const ErrorMessage: FC = ({ children }) => {
  return (
    <ErrorMessageContainer>
      <ErrorMessageText>{children}</ErrorMessageText>
    </ErrorMessageContainer>
  );
};

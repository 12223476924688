import { FC } from "react";
import styled from "styled-components";
import { Section } from "components/Section";
import { Row } from "components/Row";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import { ClimateChange, Mobility, PublicSpace } from "./data";
import { useTranslation } from "react-i18next";
import Iframe from 'react-iframe'
// import ImageVideo from "../../../icons/image"

const Container = styled(Section)`
  padding-top: 32px;
  padding-bottom: 32px;

  ${queries.md} {
    padding-top: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
    padding-bottom: ${({ paddingMD }) => (paddingMD ? paddingMD : 64)}px;
  }

  ${queries.lg} {
    padding-top: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
    padding-bottom: ${({ paddingLG }) => (paddingLG ? paddingLG : 96)}px;
  }
`;

const SpanBlock = styled.span`
position: absolute;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 133%;
  color: var(--primary-color);
 
`;

const VideoSubTitle = styled.p`
margin: 2rem 0;
font-family: "Montserrat";
font-weight: 500;
font-size: 2rem;
line-height: 133%;
padding-top: 2rem;
color: #fff;

  ${queries.onlySM} {
    font-size: 1.5rem;
  }
`;
const VideoBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  ${queries.onlySM} {
    padding-bottom: 20px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MarginRow = styled(Row)`
  ${queries.lg} {
    margin-top: 2.5rem;
  }

  ${queries.onlySM} {
    padding-top: 10px;
    justify-content: center;
  }
`;

const IframeStyle = styled(Iframe)`
    width: 100%;
    height: 25em;
    display: block;
    position: relative;
    border:none;
    border-radius:5px;
    ${queries.md} {
      margin-top: 2.5rem;
    }
    ${queries.onlySM} {
      height: 25em;
      
    }
    ${queries.xs}{
      height: 15em;
    }
`
const Margin = styled.div`
margin-bottom: 5rem
`

export const ArchiveVideo: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container id="intro">
        <SpanBlock>{t(`archive:Videos`)}</SpanBlock>
        <Margin />
        <MarginRow>
          <Col startSM={0} endSM={12} startMD={0} endMD={12}>
            <Wrapper >
              <Iframe url="https://www.youtube.com/embed/zhqs45JXRWE?start=1"
                width="100%"
                styles={{ height: "45rem" }}
                frameBorder={0}
                position="relative"
                loading='lazy'
                scrolling="no"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              />
            </Wrapper>
          </Col>
        </MarginRow>
      </Container>
      <Container id="climat">
        <VideoSubTitle>{t(`archive:ClimateChange`)}</VideoSubTitle>
        <Row>
          {ClimateChange.map(({ href }, index) => (

            <Col
              key={index}
              startMD={index % 2 === 0 || index === 0 ? 0 : 6}
              endMD={index % 2 === 0 || index === 0 ? 6 : 12}
              startSM={index % 2 === 0 || index === 0 ? 0 : 0}
              endSM={index % 2 === 0 || index === 0 ? 12 : 12}
            >
              <Wrapper>
                <IframeStyle url={href}
                  width="100%"
                  className="videoclimate"
                  position="relative"
                  loading='lazy'
                  scrolling="no"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                />
              </Wrapper>
            </Col>
          ))}
        </Row>
      </Container>
      <Container id="mobility">
        <VideoSubTitle>{t(`archive:Mobility`)}</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            {Mobility.map(({ href }, index) => (
              <>
                <Col
                  key={index}
                  startMD={index % 2 === 0 || index === 0 ? 0 : 6}
                  endMD={index % 2 === 0 || index === 0 ? 6 : 12}
                  startSM={index % 2 === 0 || index === 0 ? 0 : 0}
                  endSM={index % 2 === 0 || index === 0 ? 12 : 12}
                >
                  <Wrapper>
                    <IframeStyle url={href}
                      width="100%"
                      className="videomobility"
                      position="relative"
                      loading='lazy'
                      scrolling="no"
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    />
                  </Wrapper>
                </Col>
              </>
            ))}
          </MarginRow>
        </VideoBox>
      </Container>
      <Container id="publicspace">
        <VideoSubTitle>{t(`archive:PublicSpace`)}</VideoSubTitle>
        <VideoBox>
          <MarginRow>
            {PublicSpace.map(({ href }, index) => (
              <>
                <Col
                  key={index}
                  startMD={index % 2 === 0 || index === 0 ? 0 : 6}
                  endMD={index % 2 === 0 || index === 0 ? 6 : 12}
                  startSM={index % 2 === 0 || index === 0 ? 0 : 0}
                  endSM={index % 2 === 0 || index === 0 ? 12 : 12}
                >
                  <Wrapper>
                    <IframeStyle url={href}
                      width="100%"
                      id="videopublicspace"
                      className="videopublicspace"
                      position="relative"
                      loading='lazy'
                      scrolling="no"
                      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    />
                  </Wrapper>
                </Col>
              </>
            ))}
          </MarginRow>
        </VideoBox>
      </Container>

    </>
  );
};

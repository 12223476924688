export const ClimateChange = [
    {
        title: "Klimasken as a data tool for assessment of urban climate adaptation",
        subTitle: "Miroslav Lupač (CZ)",
        href: "https://www.youtube.com/embed/PGTCdQDWG9U",
    },
    {
        title: "Urban Data Platforms: Making Better Connections",
        subTitle: "Antony Page (UK)",
        href: "https://www.youtube.com/embed/gaHz7DgqIKo",
    },
];
export const Mobility = [
    {
        title: "Measuring people’s experiences through data",
        subTitle: "Eunsu Kim (USA) ",
        href: "https://www.youtube.com/embed/VMTqfvTcCXc",
    },
    {
        title: "The interaction between urban mobility and public space through data",
        subTitle: "Fábio Duarte (NL)",
        href: "https://www.youtube.com/embed/3y2cwl940_o",
    },
    {
        title: "Urban Data Governance in the City of Hamburg",
        subTitle: "Laura Buettner (DE)",
        href: "https://www.youtube.com/embed/StLIEz9kkXQ",
    },
    {
        title: "Open data and date re-use according to data.Brno",
        subTitle: "Martin Dvořák (CZ)",
        href: "https://www.youtube.com/embed/i6KGCJC_Yg4",
    },
    {
        title: "Prague case-study: the role of data in developing Bubny area",
        subTitle: "Jiří Čtyroký (CZ)",
        href: "https://www.youtube.com/embed/8frFat6mhSo",
    },
];
export const PublicSpace = [
    {
        title: "Ethical AI in public spaces",
        subTitle: "Peter Mantello",
        href: "https://www.youtube.com/embed/wKvUE1y_gr8",
    },
    {
        title: "Data-based approach to placemaking in Bologna",
        subTitle: "Leonardo Tedeschi (IT)",
        href: "https://www.youtube.com/embed/5RS0sJ53FKY",
    },
    {
        title: "Behavioral analysis of public space based on a smart camera system",
        subTitle: "Jana Zdráhalová (CZ)",
        href: "https://www.youtube.com/embed/N2PMnBkf9Ag",
    },
    {
        title: "Designing a Smart City based on European Values",
        subTitle: " Thijs Turel (NL)",
        href: "https://www.youtube.com/embed/YYavtK0bnLM",
    },
    {
        title: "European view on data-driven solutions",
        subTitle: "Alanus von Radecki (DE)",
        href: "https://www.youtube.com/embed/H5KwuNEjwDQ",
    },
    {
        title: "How does data from mobile providers help Prague to develop its mobility?",
        subTitle: "Jiří Čtyroký (CZ)",
        href: "https://www.youtube.com/embed/9U1MJgxCdHc",
    },
];

import { Col } from "components/Col";
import { Row } from "components/Row";
import { Section } from "components/Section";
import styled from "styled-components";
import { IMainPage } from "types/mainPageType";
import ReactMarkdown from "react-markdown";
import { queries } from "utils/queries";

const StyledTitle = styled.h2`
  font-size: 32px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 32px;
`;

const StyledRow = styled(Row)`
  background: var(--primary-color);
  padding-top: 0px;
`;

const StyledSection = styled(Section)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 0px;
  ${queries.onlySM} {
    padding-top: 32px;
  }
`;

const StyledText = styled.div`
  font-size: 20px;
  line-height: 32px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const StyledLinks = styled.div`
  a:hover,
  a:hover > *,
  a:hover svg {
    background: var(--secondary-color);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    fill: var(--primary-color);
  }
`;
const StyledXXX = styled.div`
  p {
    padding-bottom: 10px;
  }
`;

export const ContactSection = (props: { cantactsData: IMainPage }) => {
  const { cantactsData } = props;
  return (
    <StyledRow>
      <Col startMD={1} endMD={11}>
        <StyledSection paddingMD={0} paddingLG={0}>
          <StyledTitle>{cantactsData.questionsComponentTitle}</StyledTitle>
          <StyledText>
            <StyledLinks>
              <StyledXXX>
                <ReactMarkdown>
                  {cantactsData.questionsComponentDescription}
                </ReactMarkdown>
              </StyledXXX>
            </StyledLinks>
          </StyledText>
        </StyledSection>
      </Col>
    </StyledRow>
  );
};

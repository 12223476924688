import { Col } from "components/Col";
import { Row } from "components/Row";
import { SectionWithBG } from "components/Section";
import styled from "styled-components";
import { ReactComponent as SmartLogo } from "./logo smart.svg";
import { ReactComponent as GolemioLogo } from "./logo golemio.svg";
import { ReactComponent as IPRLogo } from "./logo IPR.svg";
import { ReactComponent as PrahaLogo } from "./logo praha.svg";
import { ReactComponent as PrgLogo } from "./logo prgai.svg";
import { ReactComponent as CDLogo } from "./logo cesko digital.svg";
import { ReactComponent as SVOlogo } from "./logo SVO.svg";
import { ReactComponent as OICTLogo } from "./logo OICT.svg";

import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";
import { IMainPage } from "types/mainPageType";

const IconWrapperCol = styled(Col)`
  margin: 40px auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${queries.onlySM} {
    & a {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  ${queries.md} {
    width: 1000px;
    margin: auto;
  }

  & svg {
    fill: var(--white-color);
  }
`;

const StyledGradientLink = styled(GradientA)`
  margin: 2rem;
  &:hover,
  &:hover > *,
  &:hover svg {
    background: rgba(196, 196, 196, 0.5);
    color: rgba(196, 196, 196, 0.5) #d8000c;
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: text;
    background-clip: text;
    fill: rgba(196, 196, 196, 0.5);
  }
`;

const OrderedLink = styled(StyledGradientLink)<{ order: number }>`
  ${queries.onlySM} {
    order: ${({ order }) => order};
  }
`;

export const Partners = (props: { partnersTittle: IMainPage }) => {
  const { partnersTittle } = props;
  return (
    <SectionWithBG id="partners">
      <Row>
        <Col startSM={1}>
          <h2 style={{ fontWeight: "normal" }}>
            {partnersTittle.partnersComponentTitle}
          </h2>
        </Col>
      </Row>
      <Row>
        <IconWrapperCol startMD={1} endMD={11}>
          <OrderedLink
            href="https://operatorict.cz/"
            target="_blank"
            rel="noreferrer"
            order={1}
          >
            <OICTLogo height={128} />
          </OrderedLink>
          <OrderedLink
            href="https://smartprague.eu/"
            target="_blank"
            rel="noreferrer"
            order={2}
          >
            <SmartLogo />
          </OrderedLink>
          <OrderedLink
            href="https://golemio.cz/"
            target="_blank"
            rel="noreferrer"
            order={5}
          >
            <GolemioLogo />
          </OrderedLink>
          <OrderedLink
            href="https://www.iprpraha.cz/"
            target="_blank"
            rel="noreferrer"
            order={3}
          >
            <IPRLogo />
          </OrderedLink>
          <OrderedLink
            href="https://www.praha.eu/"
            target="_blank"
            rel="noreferrer"
            order={4}
          >
            <PrahaLogo />
          </OrderedLink>
          <OrderedLink href="https://prg.ai/" target="_blank" rel="noreferrer" order={5}>
            <PrgLogo width={213} height={128} order={6} />
          </OrderedLink>
          <OrderedLink
            href="https://cesko.digital/"
            target="_blank"
            rel="noreferrer"
            order={7}
          >
            <CDLogo width={213} height={128} />
          </OrderedLink>
          <OrderedLink
            href="https://www.smocr.cz/"
            target="_blank"
            rel="noreferrer"
            order={8}
          >
            <SVOlogo width={190} height={110} />
          </OrderedLink>
        </IconWrapperCol>
      </Row>
    </SectionWithBG>
  );
};

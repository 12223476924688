import { useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import "./style.scss";
import { queries } from "utils/queries";

const CurrentLanguage = styled.div`
  ${queries.md} {
    padding-left: 10px;
  }
`;
const languageCodes = [{ code: "en" }, { code: "cz" }];

const languages = {
  en: "English",
  cz: "Česky",
};

export const LanguageSwitch = (props: any) => {
  const initialLang = () => {
    let browserLang = "";
    if (navigator.languages !== undefined) {
      browserLang = navigator.languages[0];
    } else {
      browserLang = navigator.language;
    }
    if (browserLang === "cs" || browserLang === "sk") return "cz";
    return "en";
  };

  const history = useHistory();
  const langFromStorage = localStorage.getItem("lang");

  const [lang, setLang] = useState(langFromStorage || initialLang());
  if (!langFromStorage) localStorage.setItem("lang", initialLang());

  const selectLanguageHandler = (e: any) => {
    setLang(e.target.lang as string);
    localStorage.setItem("lang", e.target.lang);

    if (history.location.pathname === "/") {
      window.location.reload();
    } else {
      history.replace(`/`);
    }
  };

  const getCurrentLanguage = () => {
    return lang === "en" ? languages.cz : languages.en;
  };

  const options = languageCodes.map((language, i) => {
    if (language.code !== lang) {
      return (
        <li key={i} onClick={selectLanguageHandler}>
          <div lang={language.code} className={`lang lang-${language.code}`}></div>
        </li>
      );
    } else {
      return <li key={i}></li>;
    }
  });

  return (
    <div className="languageFlag">
      <div className="lang">
        <a className="first" href={history.location.pathname}>
          <ul>{options}</ul>
        </a>
      </div>
      <CurrentLanguage className="langCurrent">{getCurrentLanguage()}</CurrentLanguage>
    </div>
  );
};
import { FilterKeysByValueType } from "types/filterKeysByValueType";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { InputProps } from "types/InputProps";
import { InputContainer } from "components/InputContainer";
import { ErrorMessage } from "components/ErrorMessage";
import { ReactComponent as ChevronIcon } from "./chevron.svg";
import { useEffect } from "react";

type Props<Name> = InputProps<Name> & {
  options: string[];
};

const SelectContainer = styled.div`
  position: relative;
`;

const StyledChevron = styled(ChevronIcon)`
  fill: var(--black-color);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  *:hover > & {
    fill: var(--primary-color);
  }
`;

const StyledInput = styled.select<{ isSet: boolean }>`
  background: none;
  border: none;
  width: 100%;
  color: ${({ isSet }) => (isSet ? "var(--white-color)" : "var(--black-color)")};
  font-size: 24px;
  height: 36px;
  appearance: none;
  padding: 2px 5px;

  &:focus {
    outline: none;
    /* text-indent: 5px; */
  }
`;

const StyledOption = styled.option`
  background: var(--white-color);
  color: var(--primary-color);
  font-size: 22px;
`;

export const SelectInput = <FormData extends {}>({
  label,
  name,
  options,
}: Props<string & FilterKeysByValueType<FormData, string>>) => {
  const {
    handleBlur,
    handleChange,
    values,
    validationSchema,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<FormData>();

  const required = validationSchema?.fields?.[name]?.exclusiveTests?.required === true;
  const isTouched = touched?.[name];
  const errorMessage = errors?.[name];
  const value = values[name] as unknown as string;

  useEffect(() => {
    if (!options.includes(value)) {
      setFieldValue(name, "");
    }
  }, [options, value, setFieldValue, name]);

  return (
    <InputContainer>
      <SelectContainer>
        <StyledInput
          value={value}
          name={name}
          required={required}
          onChange={handleChange}
          onBlur={handleBlur}
          isSet={!!value}
        >
          <StyledOption value="" disabled hidden>{`${label}${required ? " *" : ""
            }`}</StyledOption>
          {options.map((option) => (
            <StyledOption key={option} value={option}>
              {option}
            </StyledOption>
          ))}
        </StyledInput>
        <StyledChevron />
      </SelectContainer>
      <ErrorMessage>{!!isTouched && errorMessage}</ErrorMessage>
    </InputContainer>
  );
};
import { ErrorMessageText } from "components/ErrorMessage";
import { useFormikContext } from "formik";
import { CheckboxProps, useCheckbox } from "hooks/useCheckbox";
import { useEffect } from "react";
import styled from "styled-components";
import { AgreementText } from "./AgreementText";

const AgreementContainer = styled.div`
  margin-bottom: 32px;
`;

const StyledLabel = styled.span`
  color: var(--black-color);
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

type Props<FormData> = Omit<CheckboxProps<FormData>, "label"> & {
  attendInPerson: boolean;
};

export const AgreementCheckbox = <FormData extends { agreement: boolean }>({
  name,
  attendInPerson,
}: Props<FormData>) => {
  const { checkboxElement, isTouched, errorMessage } = useCheckbox<FormData>({
    label: "",
    name,
  });
  const { setFieldValue } = useFormikContext<FormData>();

  useEffect(() => {
    setFieldValue("agreement", false);
  }, [attendInPerson, setFieldValue]);

  return (
    <AgreementContainer>
      <CheckboxContainer>
        <div>{checkboxElement}</div>
        <StyledLabel>{<AgreementText />}</StyledLabel>
      </CheckboxContainer>
      <ErrorMessageText>{isTouched && errorMessage}</ErrorMessageText>
    </AgreementContainer>
  );
};

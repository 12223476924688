import { useFormikContext } from "formik";
import styled from "styled-components";
import { FilterKeysByValueType } from "types/filterKeysByValueType";
import { InputProps } from "types/InputProps";
import { ErrorMessage } from "./ErrorMessage";
import { InputContainer } from "./InputContainer";

const StyledInput = styled.input`
  background: none;
  border: none;
  width: 100%;
  color: var(--white-color);
  font-size: 24px;
  line-height: 26px;
  padding: 2px 5px;

  &::placeholder {
    color: var(--black-color);
  }

  &:focus {
    outline: none;
  }
`;

type Props<Name> = InputProps<Name> & {
  readonly type?: "email" | "text";
};

export const TextInput = <FormData extends {}>({
  label,
  type = "text",
  name,
}: Props<string & FilterKeysByValueType<FormData, string>>) => {
  const { handleBlur, handleChange, values, validationSchema, errors, touched } =
    useFormikContext<FormData>();

  const required = validationSchema?.fields?.[name]?.exclusiveTests?.required === true;
  const isTouched = touched?.[name];
  const errorMessage = errors?.[name];

  return (
    <InputContainer>
      <StyledInput
        placeholder={`${label}${required ? " *" : ""}`}
        type={type}
        name={name}
        value={values[name] as unknown as string}
        required={required}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage>{!!isTouched && errorMessage}</ErrorMessage>
    </InputContainer>
  );
};
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";

interface ContextValue {
  viewportWidth: number;
}

const ViewportWidthContext = createContext<ContextValue>({ viewportWidth: window.innerWidth });

export const ViewportWidthProvider: FC = ({ children }) => {
  const initialWidth = window.innerWidth;
  const [viewportWidthToDebounce, setViewportWidthToDebounce] = useState(initialWidth);
  const [viewportWidth, setViewportWidth] = useState(initialWidth);

  useLayoutEffect(() => {
    const handleResize = () => {
      setViewportWidthToDebounce(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (viewportWidthToDebounce !== viewportWidth) {
        setViewportWidth(viewportWidthToDebounce);
      }
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportWidthToDebounce]);

  const viewportContextValue = useMemo(() => ({ viewportWidth }), [viewportWidth]);

  return (
    <ViewportWidthContext.Provider value={viewportContextValue}>
      {children}
    </ViewportWidthContext.Provider>
  );
};

export const useViewportWidth = () => {
  const { viewportWidth } = useContext(ViewportWidthContext);

  return { viewportWidth };
};

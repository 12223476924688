import { FC, ReactNode } from "react";
import styled from "styled-components";
import { queries } from "utils/queries";

const Tab = styled.div<{ active: boolean }>`
  ${queries.md} {
    display: ${({ active }) => (active ? "block" : "none")};
  }
`;

export const Tabs: FC<{ tabs: ReactNode[]; selectedTabIndex: number }> = ({
  tabs,
  selectedTabIndex,
}) => {
  if (!tabs.length) {
    return null;
  }

  return (
    <>
      {tabs.map((tab, index) => (
        <Tab key={index} active={selectedTabIndex === index}>
          {tab}
        </Tab>
      ))}
    </>
  );
};

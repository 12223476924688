import React from "react";

export default function dataProgramEn() {
  // export const dataProgramEn = () => {
  return (
    <div>
      <p>
        We are currently putting together the 2022 program and will publish it
        as soon as it’s ready. However we can already announce that this year’s-
        edition will open up to a diverse audience with a wide range
        ofparticipation possibilities in a festival-like fashion.
      </p>
      <br />
      <p>
        During the day, there will be <b>workshops</b> for the more
        tech-oriented crowd, <b>international case-study engagement sessions</b>
        for city officials and their associates, and <b>city walks </b>for the
        broader public. In the evenings we will open the congress to the general
        public with <b>keynote lectures</b>, which will then be followed by an
        afterparty.
      </p>
      <br />
      <p>
        Each of the keynote presentations will offer a glance into the topics
        more widely discussed during the walks, workshops, and interactive
        sessions. Two of the latter will offer a deep dive and opportunity for
        local policymakers and tech enthusiasts to connect with their
        international colleagues.
      </p>
    </div>
  );
}

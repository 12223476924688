export type RegisterAsOption = "Visitor" | "Press" | "Speaker";

export const registerAsOptions: RegisterAsOption[] = ["Visitor", "Press", "Speaker"];

/*
export const registerAsOptions: RegisterAsOption[] = [
  "Visitor",
  "Press",
  "Speaker",
];
*/

export const organizationTypeVisitorOptions = [
  "Government",
  "Non-profit",
  "Private",
  "University",
];
export const organizatoinTypePressOptions = ["Print media", "Television", "Online media"];

export type AttendanceOption = "In person" | "Online";
export const attendanceOptions: AttendanceOption[] = ["In person", "Online"];

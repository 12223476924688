import { FC, useEffect, useState } from "react";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { Partners } from "./sections/Partners";
import { Registration } from "./sections/Registration";
import { Feedback } from "./sections/Feedback";
import { About } from "./sections/About";
import { Intro } from "./sections/Intro";
import { Location } from "./sections/Location";
import { Layout } from "components/Layout";
import { ContactSection } from "./sections/Contact";
import { configuration } from "configuration";
import {
  IAboutComponent,
  ISliderComponent,
  SeeMoreLinks,
  IMainPage,
  ISliderItem,
} from "types/mainPageType";

import mainCs from "../../data/cs/main.json";
import mainEn from "../../data/main.json";

export interface IFetchedDataTranformed {
  aboutComponent: IAboutComponent;
  sliderComponent: ISliderComponent;
  seeMoreLinks: SeeMoreLinks;
  mainPage: IMainPage;
}
export const MainView: FC = () => {
  const [isLoading] = useState(false);
  const [hasFailed] = useState(false);
  const [fetchedDataTranformed, setFetchedDataTranformed] =
    useState<IFetchedDataTranformed | null>(null);

  useEffect(() => {
    const langFromStorage = localStorage.getItem("lang");
    const fetchedItem = langFromStorage === "en" ? mainEn[0].attributes : mainCs[0].attributes;

    const sliderItems: ISliderItem[] = fetchedItem.sliderComponent.sliderItems.map(
        (slider: any) => {
          return {
            sliderItemTitle: slider.title,
            sliderItemContentArr: [
              slider.firstParagraph,
              slider.secondParagraph,
            ],
            sliderId: slider.id,
            image: slider.img.data.attributes.url,
          };
        }
    );

    const _fetchedDataTranformed: IFetchedDataTranformed = {
      aboutComponent: {
        titleAbout: fetchedItem.aboutComponent.title,
        aboutParagraph: fetchedItem.aboutComponent.paragraphs.map(
            (textItem: any) => {
              return { text: textItem.richText };
            }
        ),
      },
      sliderComponent: {
        sliderItems: sliderItems,
      },

      seeMoreLinks: fetchedItem.seeMoreLinks.map((linkObj: any) => {
        return {
          linkTitle: linkObj.title,
          targetUrl: linkObj.targetUrl,
        };
      }),

      mainPage: {
        seeMoreComponentTitle: fetchedItem.seeMoreComponentTitle,
        feedbacksComponentTitle: fetchedItem.feedbacksComponentTitle,
        addressComponentTitle: fetchedItem.addressComponentTitle,
        addressComponentLinkTitle: fetchedItem.addressComponentLinkTitle,
        partnersComponentTitle: fetchedItem.partnersComponentTitle,
        questionsComponentTitle: fetchedItem.questionsComponentTitle,
        registrationCmponentTitle: fetchedItem.registrationCmponentTitle,
        questionsComponentDescription:
        fetchedItem.questionsComponentDescription,
        dateOfcongress: fetchedItem.firstScreenDate,
        firstScreenAddressText: fetchedItem.firstScreenAddressText,
        firstScreenAddressTextSecondLine: fetchedItem.firstScreenAddressTextSecondLine
      },
    };

    setFetchedDataTranformed(_fetchedDataTranformed);
  }, []);

  return (
    <Layout header={<Header />} footer={<Footer />}>
      {!hasFailed && !isLoading && fetchedDataTranformed && (
        <Intro dateOfcongress={fetchedDataTranformed.mainPage} screenAddressText={fetchedDataTranformed.mainPage} />
      )}
      {isLoading && <>loading...</>}
      {!hasFailed && !isLoading && fetchedDataTranformed && (
        <About
          aboutComponentData={fetchedDataTranformed.aboutComponent}
          sliderComponentData={fetchedDataTranformed.sliderComponent}
        />
      )}
      {/* <ProgramSection /> */}
      {/* <SpeakersSection /> */}
      {/* {!hasFailed && !isLoading && fetchedDataTranformed && (
        <ComingSoon
          mainPageTitle={fetchedDataTranformed.mainPage}
          mainPageSeeMoreLinks={fetchedDataTranformed.seeMoreLinks}
        />
      )} */}

      {!hasFailed && !isLoading && fetchedDataTranformed && (
        <Feedback
          feedbacksTitleData={
            fetchedDataTranformed.mainPage.feedbacksComponentTitle
          }
        />
      )}

      {!hasFailed && !isLoading && fetchedDataTranformed && configuration.SHOW_REGISTRATION && (
          <Registration ragistrationTitle={fetchedDataTranformed.mainPage} />
      )}

      {!hasFailed && !isLoading && fetchedDataTranformed && (
        <Location
          addressTittle={fetchedDataTranformed.mainPage}
          screenAddressText={fetchedDataTranformed.mainPage}
          screenAddressTextSecond={fetchedDataTranformed.mainPage}
        />
      )}
      {!hasFailed && !isLoading && fetchedDataTranformed && (
        <Partners partnersTittle={fetchedDataTranformed.mainPage} />
      )}
      {!hasFailed && !isLoading && fetchedDataTranformed && (
        <ContactSection cantactsData={fetchedDataTranformed.mainPage} />
      )}
    </Layout>
  );
};

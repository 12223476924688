import { TextWithArrowIcon } from "components/TextWithArrowIcon";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { RelativeSection } from "components/Section";
import styled, { css } from "styled-components";
import { UnderlinedText } from "components/UnderlinedText";
import { queries } from "utils/queries";
import { GradientA } from "components/GradientLink";
import { configuration } from "configuration";
import { IMainPage } from "types/mainPageType";
import LazyLoad from "react-lazy-load";

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 32px;

  font-size: 20px;
  line-height: 32px;

  ${queries.md} {
    font-size: 36px;
    line-height: 48px;
  }
`;

const SpanBlock = styled.span`
  display: block;
`;

const Background = styled.div<{ showRegistration: boolean }>`
  z-index: -1;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: url(/images/venue.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;

  ${queries.md} {
    ${({ showRegistration }) =>
    showRegistration &&
    css`
        height: 962px;
      `}
  }

  ${({ showRegistration }) =>
    !showRegistration &&
    css`
      height: auto;
      top: 0;
    `}
`;

const GradientLink = styled(GradientA)`
  &:hover,
  &:hover > *,
  &:hover svg {
    background: rgba(196, 196, 196, 0.5);
    color: rgba(196, 196, 196, 0.5);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: text;
    background-clip: text;
    fill: rgba(196, 196, 196, 0.5);
    cursor: pointer;
  }
`;

const RelativeSectionWithMargin = styled(RelativeSection)`
  //margin-top: 128px;
`;

export const Location = (props: {
  addressTittle: IMainPage;
  screenAddressText: IMainPage;
  screenAddressTextSecond: IMainPage
}) => {
  const { addressTittle, screenAddressText, screenAddressTextSecond } = props;
  return (
    <RelativeSectionWithMargin id="venue">
      <LazyLoad>
        <Background showRegistration={configuration.SHOW_REGISTRATION} />
      </LazyLoad>
      <Row>
        <Col startMD={1} endMD={11}>
          <Title>
            {/* <SpanBlock>The congress is happening in</SpanBlock> */}
            <SpanBlock>{addressTittle.addressComponentTitle}</SpanBlock>
            <SpanBlock>{screenAddressText.firstScreenAddressText}</SpanBlock>
            <SpanBlock>{screenAddressTextSecond.firstScreenAddressTextSecondLine}</SpanBlock>
          </Title>
          <GradientLink
            href="https://goo.gl/maps/AMAvQkCxhWx1BVDz6"
            target="_blank"
            rel="noreferrer"
          >
            <TextWithArrowIcon size={24}>
              <UnderlinedText>
                {addressTittle.addressComponentLinkTitle}
              </UnderlinedText>
            </TextWithArrowIcon>
          </GradientLink>
        </Col>
      </Row>
    </RelativeSectionWithMargin>
  );
};

import { GlobalStyles } from "components/GlobalStyles";
import { Router } from "components/Router";
import { ProgramsProvider } from "hooks/useProgram";
import { ViewportWidthProvider } from "hooks/useViewportWidth";
import moment from "moment";
// google tag manager implementation
import TagManager, { TagManagerArgs } from "react-gtm-consent-module";

const tagManagerArgs: TagManagerArgs = {
  gtmId: window.ENV.GTM_ID,
};

TagManager.initialize(tagManagerArgs);

moment.defaultFormat = "dddd D. M. y";

function App() {
  return (
    <ViewportWidthProvider>
      <ProgramsProvider>
        <GlobalStyles />
        <Router />
      </ProgramsProvider>
    </ViewportWidthProvider>
  );
}

export default App;

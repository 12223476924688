import { Col } from "components/Col";
import { Row } from "components/Row";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Speaker } from "types/speaker";
import { queries } from "utils/queries";
import { GradientLink } from "./GradientLink";
import { TintedImage } from "./TintedImage";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load"
import dataSpeakersEn from "../data/speakers.json";
import dataSpeakersCs from "../data/cs/speakers.json";


const SpeakersContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
  width: 100%;
  column-gap: 32px;
  row-gap: 120px;
  max-height: 100%;

  ${queries.onlySM} {
    row-gap: 20px;
  }
`;

const SpeakerLink = styled(GradientLink)`
  grid-column: auto;
  max-width: 336px;
  width: 100%;

  ${queries.onlySM} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const SpeakerName = styled.h3`
  //margin-top: 40px;
  margin-bottom: 0.5em;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`;

const SpeakerTitle = styled.p`
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
`;
const CommingSoon = styled.p`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: -15em;
`;

interface Props {
  readonly count?: number;
  readonly className?: string;
}

const SpeakerLinkBox: FC<{ url: string }> = ({ children, url }) => (
  <SpeakerLink to={url}>{children}</SpeakerLink>
);

export const Speakers: FC<Props> = ({ count, className }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [speakers, setSpeakers] = useState<Speaker[]>([]);
  const [hasFailed] = useState(false);

  const getSpeakersByLang = (currentLang: any): any[] => {
    return currentLang === "en" ? dataSpeakersEn : dataSpeakersCs;
  };

  useEffect(() => {
    const langFromStorage = localStorage.getItem("lang");
    setIsLoading(true);
    const speakersArr: Speaker[] = getSpeakersByLang(langFromStorage).map((speaker: any) => {
      return {
        name: speaker.attributes.name,
        title: speaker.attributes.title,
        company: speaker.attributes.company,
        companyUrl: speaker.attributes.companyUrl,
        year: speaker.attributes.year,
        description: speaker.attributes.description,
        slug: speaker.attributes.slug,
        picture: {
          url: speaker.attributes.picture.data.attributes.url,
        },
        id: speaker.id,
      };
    });

    setSpeakers(speakersArr);
    setIsLoading(false);

  }, [count]);

  return (
    <Row className={className}>
      <Col startMD={1} endMD={11}>
        {isLoading && <>loading...</>}
        {hasFailed && (
          <>
            There was a problem while downloading speakers, please try again
            later.
          </>
        )}
        {!hasFailed && !isLoading && speakers.length === 0 && (
          <CommingSoon> {t(`speakers:сomingsoon`)}</CommingSoon>
        )}
        {!hasFailed && !isLoading && speakers.length > 0 && (
          <SpeakersContainer>
            {speakers.map((speaker) => (
              <SpeakerLinkBox key={speaker.name} url={`/speaker/${speaker.slug}`}>
                <LazyLoad>
                  <TintedImage src={speaker.picture?.url} alt={speaker.name} />
                </LazyLoad>
                <SpeakerName>{speaker.name}</SpeakerName>
                <SpeakerTitle>{speaker.title}</SpeakerTitle>
              </SpeakerLinkBox>
            ))}
          </SpeakersContainer>
        )}
      </Col>
    </Row>
  );
};

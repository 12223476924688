import { GradientA } from "components/GradientLink";
import { configuration } from "configuration";
import { FC, MouseEvent as ReactMouseEvent, useCallback } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { queries } from "utils/queries";
import { links } from "./links";
import { useTranslation } from "react-i18next";
import { onNavLinkClickCallback } from "helpers/onNavLinkClickCallback";

const List = styled.ul`
  text-align: right;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 1.5rem;
  display: none;

  & li {
    display: inline-block;

    &:not(:first-child) {
      margin-left: 32px;
    }
  }
  & a {
    line-height: 40px;
  }

  ${queries.md} {
    display: block;
  }
`;

export const InlineNavigation: FC = () => {
  const langFromStorage = localStorage.getItem("lang");
  const { t } = useTranslation();
  const { pathname } = useLocation();
  let linkCz = "/cz";
  const onNavLinkClick = useCallback(
    (e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
      return onNavLinkClickCallback(e, link, pathname);
    },
    [pathname]
  );

  if (langFromStorage === "cz") {
    if (pathname.slice(0, 3) === "/cz") {
      linkCz = ""
    }
    window.history.replaceState({ url: configuration.API_URL }, "", linkCz + [pathname]);
  }

  return (
    <List>
      {links(langFromStorage).map(({ label, link, external }, index) => (
        <li key={index}>
          <GradientA
            href={link}
            onClick={external ? undefined : (e) => onNavLinkClick(e, link)}
            target={external ? "_blank" : undefined}
            rel={external ? "noreferrer" : undefined}
          >
            {t(`menu:${label}`)}
          </GradientA>
        </li>
      ))}
    </List>
  );
};

import styled from "styled-components";
import { queries } from "utils/queries";

const Wave = styled.div`
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;

  ${queries.onlySM} {
    display: none;
  }
`;

export const RightWave1 = styled(Wave)`
  background-image: url(/images/waves/about.png);
  background-position: bottom right;
  background-size: 100%;
`;

export const RightWave2 = styled(Wave)`
  background-image: url(/images/waves/feedbacks.png);
  background-position: bottom right;
  background-size: 100%;
  bottom: -350px;
`;

export const LeftWave1 = styled(Wave)`
  background-image: url(/images/waves/registr.png);
  background-position: bottom left;
  background-size: 100%;
`;

export const LeftWave2 = styled(Wave)`
  background-image: url(/images/waves/about.png);
  background-position: bottom left;
  background-size: 100%;
  left: -350px;
`;


export const newExpressions = [
    { url: "https://www.youtube.com/embed/26AaLAkgOys" },
    { url: "https://www.youtube.com/embed/0AuetXoRp0k" },
    { url: "https://www.youtube.com/embed/sdGRnjczhOU" },
    { url: "https://www.youtube.com/embed/vWiSDZJK--Q" },
    { url: "https://www.youtube.com/embed/7nS6lIY_2Bo" },
];

export const designAccessibility = [
    { url: "https://www.youtube.com/embed/dna-AQr-wcs" },
    { url: "https://www.youtube.com/embed/rS_RSgFCBWQ" },
    { url: "https://www.youtube.com/embed/cj8ICl5kpYc" },
    { url: "https://www.youtube.com/embed/v_UTvu3tc00" },
    { url: "https://www.youtube.com/embed/r5e4os18Ewo" },
    { url: "https://www.youtube.com/embed/P8okuiPYo6A" },
];
export const dataNarratives = [
    { url: "https://www.youtube.com/embed/MThBEi8XjPY" },
    { url: "https://www.youtube.com/embed/QCJah0hwXcg" },
    { url: "https://www.youtube.com/embed/teLsqrgLkZI" },
    { url: "https://www.youtube.com/embed/nwt7wnKHeAk" },
];

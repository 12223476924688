import { SelectInput } from "components/SelectInput";
import { FilterKeysByValueType } from "types/filterKeysByValueType";
import { InputProps } from "types/InputProps";
import { countries } from "./countries";

export const CountryPicker = <FormData extends {}>(
  props: InputProps<string & FilterKeysByValueType<FormData, string>>
) => {
  return <SelectInput<FormData> {...props} options={countries} />;
};

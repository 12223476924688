import { FC } from "react";
import styled from "styled-components";
import { Col } from "./Col";
import { Row } from "./Row";
import { queries } from "utils/queries";

const StyledH2 = styled.h2<{ marginTopRem?: number }>`
  display: flex;
  align-items: center;
  margin-top: ${(p) => (p.marginTopRem ? `${p.marginTopRem / 2}rem` : 0)};
  padding-bottom: 48px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  margin-bottom: 4rem;
  ${queries.md} {
    margin-top: ${(p) => (p.marginTopRem ? `${p.marginTopRem}rem` : 0)};
    margin-bottom: 8rem;
  }
`;

export const Title: FC<{ marginTopRem: number }> = ({ children, marginTopRem }) => {
  return (
    <Row>
      <Col startMD={1} endMD={11}>
        <StyledH2 marginTopRem={marginTopRem}>{children}</StyledH2>
      </Col>
    </Row>
  );
};

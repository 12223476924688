import { FC } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "./marker.svg";

const StyledIcon = styled(Icon)`
  height: 2rem;
  justify-self: start;

  &:hover > * {
    fill: var(--primary-color);
  }
`;

export const MarkerIcon: FC<{ size?: number }> = ({ size = 24 }) => {
  return <StyledIcon width={size} />;
};

import styled from "styled-components";
import { queries } from "utils/queries";

export const SmallerTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  ${queries.onlySM} {
    margin-bottom: 32px;
  }

  ${queries.md} {
    font-size: 32px;
    line-height: 40px;
  }
`;

import { FC } from "react";
import { ArrowIcon } from "components/ArrowIcon";
import { Row } from "components/Row";
import styled from "styled-components";
import { GradientLink } from "components/GradientLink";
import { NavLinks } from "components/NavLinks";
import { Col } from "components/Col";
import { queries } from "utils/queries";
import { useTranslation } from "react-i18next";


const ArrowButton = styled.button`
  font-weight: bold;
  font: 2.75rem var(--white-color);
  background: none;
  border: none;
  height: 3rem;
  top: 16.06rem;

  & svg {
    fill: #7788c3;
  }
  &:hover svg {
    fill: var(--primary-color);
  }
`;
const RotatedButton = styled(ArrowButton)`
  text-align: left;
  margin-top: 5rem;


  & svg {
    transform: rotate(180deg);
  }
`;

const PreviousYear = styled.a`
  font-weight: bolt;
  font-size: 2rem;
  line-height: 3rem;
  color:var(--white-color);
  padding-left: 2rem;

  ${queries.onlySM} {
    font-size: 1.7rem;
    padding-left: 0.5rem;
  }
  &:hover {
    text-decoration: underline;
  }
`;
const LinksBox = styled.div`
  text-align: right;
  font-family: "Montserrat";
  font-size: 2rem;
  line-height: 3rem;
  width: 100% important!;
  margin-top: 5rem;
  ${queries.onlySM} {
    font-size: 1.7rem;
  }
`;

const ActiveYear = styled(GradientLink)`
  background: var(--primary-color);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  fill:var(--primary-color);
`;

export const ArchiveLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col startMD={0} endMD={6} startSM={0} endSM={12}>
          <RotatedButton>
            <ArrowIcon />
            <PreviousYear href="/archive/2019" rel="noreferrer">
              {t(`archive:PreviousYear`)}
            </PreviousYear>
          </RotatedButton>
        </Col>
        <Col startMD={6} endMD={12} startSM={0} endSM={12}>
          <LinksBox>
            <NavLinks margin={20}>
              <li>
                <ActiveYear to="/archive/2022" rel="noreferrer">
                  2022
                </ActiveYear>
              </li>
              <li>
                <GradientLink to="/archive/2021" rel="noreferrer">
                  2021
                </GradientLink>
              </li>
              <li>
                <GradientLink to="/archive/2020" rel="noreferrer">
                  2020
                </GradientLink>
              </li>
              <li>
                <GradientLink to="/archive/2019" rel="noreferrer">
                  2019
                </GradientLink>
              </li>
            </NavLinks>
          </LinksBox>
        </Col>
      </Row>
    </div>
  );
};

import styled from "styled-components";
import { Col } from "components/Col";
import { Row } from "components/Row";
import { MarkerIcon } from "components/MarkerIcon";
import { queries } from "utils/queries";
import { IMainPage } from "types/mainPageType";
import { ReactComponent as CrossIcon } from "components/Header/PCDC_cross_logo.svg";

const RowNoGap = styled(Row)`
  row-gap: 0;
`;

const GridLine = styled(Col)`
  display: none;

  ${queries.md} {
    display: grid;
    grid-template: "c1 c2 c3 c4" / 55.9% 28.5% 28% 1fr;
  }
`;

interface StyledProps {
  area: string;
}

const Cross = styled(CrossIcon)<StyledProps>`
  grid-area: ${(props) => props.area};
  width: 2rem;
  height: 2rem;

  ${queries.md} {
    position: relative;
    left: -1rem;
  }
`;

const Grid = styled(Col)`
  display: grid;
  grid-template:
    "Heading1"
    "FirstSubtitle"
    "SecondSubtitle"
    "BookSeatButton";

  ${queries.md} {
    grid-template:
      "Heading1 SecondSubtitle"
      "BookSeatButton SecondSubtitle" / 55% 1fr;
  }
`;

const SpanBlock = styled.span`
  grid-area: Heading1;
  display: block;
  margin-top: 1.5rem;

  ${queries.md} {
    margin-top: 0rem;
  }
`;

const ColoredSpanBlock = styled(SpanBlock)`
  grid-area: Heading2;
  margin-top: 0px;
  color: var(--primary-color);
`;

const H1 = styled.h1`
  grid-area: Heading1;
  font-size: 20px;
  line-height: 24px;

  ${queries.md} {
    font-weight: 400;
    font-size: 38px;
    line-height: 36px;
    padding: 0 0 1.5rem 2rem;
  }
`;

const Subtitle = styled.p`
  &,
  & a {
    font-family: var(--primary-font), sans-serif;
    font-weight: 500;
    display: inline-block;

    font-size: 16px;
    line-height: 40px;

    ${queries.md} {
      font-size: 24px;
    }
  }

  & a {
    line-height: 20px;
    text-decoration: underline;
  }

  & a:hover {
    color: var(--primary-color);
  }
`;

const FirstSubtitle = styled(Subtitle)`
  grid-area: FirstSubtitle;
  margin-bottom: 8px;
  line-height: 24px;
  margin-top: 24px;

  ${queries.md} {
    display: none;
  }

  ${queries.lg} {
    margin-top: 104px;
  }
`;

const SecondSubtitle = styled(Subtitle)`
  grid-template-columns: SecondSubtitle;
  display: grid;
  grid-template: "Icon Date" "Icon Address" / 2.5rem auto;
  margin-top: 40px;
  line-height: 24px;

  ${queries.md} {
    line-height: 40px;
    margin-top: 0px;
    grid-row: span 2;
    grid-template: "Date Address" "Date Icon" "Date Icon" / 65% 50%;
  }

  ${queries.lg} {
    grid-template: "Date Address" "Date Icon" "Date Icon" / 72% 45%;
  }
`;

const IconSpan = styled.span`
  grid-area: Icon;
  align-self: end;
  position: relative;

  ${queries.md} {
    padding: 0 0 1.5rem 2.5rem;
    align-self: start;
  }

  ${queries.lg} {
    padding: 0 0 1rem 2rem;
  }
  & a {
    width: 2rem;

    ${queries.md} {
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        top: -0.5rem;
        left: -0.5rem;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: var(--primary-color);
        z-index: -1;
      }
      &:hover::after {
        background-color: var(--white-color);
      }
    }
  }
`;

const DateSpan = styled.span`
  grid-area: Date;

  ${queries.md} {
    font-weight: 400;
    font-size: 38px;
    line-height: 40px;
    padding: 0 0 1.5rem 2.5rem;
  }

  ${queries.lg} {
    padding: 0 4rem 1.5rem 3rem;
    width: 80%;
  }
`;

const AddressSpan = styled.span`
  grid-area: Address;

  & a {
    text-decoration: none;

    ${queries.md} {
      font-weight: 400;
      line-height: 30px;
      word-break: break-word;
      white-space: pre-line;
      padding: 0 0 1rem 2rem;
    }

    ${queries.lg} {
      padding: 0 0 1rem 1.5rem;
    }
  }
`;



export const Intro = (props: {
  dateOfcongress: IMainPage;
  screenAddressText: IMainPage;
}) => {
  const { dateOfcongress, screenAddressText } = props;

  return (
    <RowNoGap>
      <GridLine startMD={1} endMD={10}>
        <Cross area="c1" />
        <Cross area="c2" />
        <Cross area="c3" />
        <Cross area="c4" />
      </GridLine>
      <Grid startMD={1} endMD={10}>
        <H1>
          <SpanBlock>Prague City</SpanBlock>
          <ColoredSpanBlock>Data Congress</ColoredSpanBlock>
        </H1>
        <FirstSubtitle>Looking beyond the numbers</FirstSubtitle>
        <SecondSubtitle>
          <IconSpan>
            <a
              href="https://goo.gl/maps/AMAvQkCxhWx1BVDz6"
              target="_blank"
              rel="noreferrer"
            >
              <MarkerIcon size={32} />
            </a>
          </IconSpan>
          <DateSpan>{dateOfcongress.dateOfcongress}</DateSpan>
          <AddressSpan>
            <a
              href="https://goo.gl/maps/AMAvQkCxhWx1BVDz6"
              target="_blank"
              rel="noreferrer"
            >
              {screenAddressText.firstScreenAddressText}
            </a>
          </AddressSpan>
        </SecondSubtitle>
      </Grid>
      <GridLine startMD={1} endMD={10}>
        <Cross area="c1" />
        <Cross area="c2" />
        <Cross area="c3" />
        <Cross area="c4" />
      </GridLine>
    </RowNoGap>
  );
};

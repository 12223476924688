import { Col } from "components/Col";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Layout } from "components/Layout";
import { Row } from "components/Row";
import { useProgramsData } from "hooks/useProgram";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import { Topic } from "types/programType";
import { Speaker } from "types/speaker";
import moment from "moment";
import { formatTime } from "utils/timeUtils";
import { queries } from "utils/queries";
import { EventSpeaker } from "./EventSpeaker";
import { Link } from "react-router-dom";
import { UnderlinedLinkWrapper } from "components/UnderlinedLinkWrapper";
import { UnderlinedText } from "components/UnderlinedText";
import { GradientLink } from "components/GradientLink";
import { ArrowIcon } from "components/ArrowIcon";
import { useTranslation } from "react-i18next";
import dataSpeakersEn from "../../data/speakers.json";
import dataSpeakersCs from "../../data/cs/speakers.json";

const TimeText = styled.div`
  border: 1px solid var(--primary-color);
  padding: 16px 32px;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 32px;
  display: inline-block;

  & > * {
    display: inline-block;
  }

  & > *:not(:last-child) {
    padding-right: 24px;
    margin-right: 24px;
    border-right: 1px solid var(--white-color);
  }
`;

const TopicContainer = styled.div`
  margin-bottom: 96px;

  ${queries.onlySM} {
    margin-bottom: 64px;
  }
`;

const ParticipantsInfo = styled.p`
  margin-bottom: 0;
  margin-top: 32px;
  line-height: 32px;
`;

const SpeakerContainer = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  width: 100%;
  column-gap: 32px;
  row-gap: 64px;
`;

const StyledRow = styled(Row)`
  padding-bottom: 128px;
`;

const EventDescription = styled.p`
  white-space: pre-line;
`;

const ArrowButton = styled.button`
  background: url(#paint0_linear);
  border: none;

  & svg {
    fill: var(--primary-color);
  }

  &:hover svg {
    fill: var(--secondary-color);
  }
`;

const RotatedButton = styled(ArrowButton)`
  margin-right: 32px;
  margin-bottom: 32px;

  & svg {
    transform: rotate(180deg);
  }
`;

const TextButton = styled(UnderlinedText)`
  color: var(--primary-color);
  text-decoration: none;
  margin-left: 10px;
  font-size: 18px;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  width: 16px;
  height: 16px;
`;

const StyledGradientLink = styled(GradientLink)`
  &:hover,
  &:hover > *,
  &:hover svg {
    background: var(--white-color);
    color: var(--white-color);
    text-shadow: 0px 2px 36px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: text;
    background-clip: text;
    fill:var(--white-color);
  }
`;

export const EventView: FC = () => {
  const { eventId, speakerId } = useParams<{
    eventId?: string;
    speakerId?: string;
  }>();
  const { programs } = useProgramsData();
  const [speakers, setSpeakers] = useState<Speaker[] | null>(null);
  const { t } = useTranslation();

  const filteredTopics = useMemo(() => {
    const topics: (Topic & { date: Date })[] = [];
    if (speakerId) {
      programs?.forEach((program) => {
        program.eventDays.forEach((day) => {
          day.topics.forEach((topic) => {
            topic.speakers.forEach((speaker) => {
              if (`${speaker.slug}` === speakerId) {
                topics.push({ ...topic, date: day.date });
              }
            });
          });
        });
      });
    } else {
      programs?.forEach((program) => {
        program.eventDays.forEach((day) => {
          day.topics.forEach((topic) => {
            if (`${topic.slug}` === eventId) {
              topics.push({ ...topic, date: day.date });
            }
          });
        });
      });
    }
    return topics;
  }, [programs, speakerId, eventId]);

  useEffect(() => {
    if (speakerId) {
      let allSpeakers = [...dataSpeakersCs, ...dataSpeakersEn];
      let speaker = allSpeakers.find((speaker) => speaker.id === Number(speakerId));

      const speakerArr: Speaker[] = [
        {
          name: speaker!.attributes.name,
          title: speaker!.attributes.title,
          company: speaker!.attributes.company,
          companyUrl: speaker!.attributes.companyUrl,
          year: speaker!.attributes.year,
          description: speaker!.attributes.description,
          picture: {
            url: speaker!.attributes.picture.data.attributes.url,
          },
          id: speaker!.id,
          slug: speaker!.attributes.slug,
        },
      ];
      setSpeakers(speakerArr);
    } else {
      const topicSpeakers =
        filteredTopics?.find((topic) => `${topic.slug}` === eventId)?.speakers ||
        null;

      if (topicSpeakers) {
        setSpeakers(topicSpeakers);
      }
    }
  }, [speakerId, eventId, filteredTopics]);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash && filteredTopics) {
      const topicEl = document.body.querySelector(
        `#topic-${hash.substring(1)}`
      );
      topicEl?.scrollIntoView({ block: "start" });
    } else if (!hash) {
      document.body.scrollIntoView({ block: "start" });
    }
  }, [filteredTopics, hash]);

  return (
    <Layout header={<Header />} footer={<Footer />}>
      <StyledRow>
        <Col startMD={1} endMD={7}>
          <StyledGradientLink to="/program">
            <RotatedButton>
              <StyledArrowIcon />
              <TextButton>{t(`eventView:link`)}</TextButton>
            </RotatedButton>
          </StyledGradientLink>

          {filteredTopics?.map((topic) => (
            <TopicContainer key={topic.id} id={`topic-${topic.slug}`}>
              <h2>{topic.title}</h2>
              <TimeText>
                <div>{moment(topic.date).format()}</div>
                <div>
                  {formatTime(topic.timeFrom)} - {formatTime(topic.timeTo)}
                </div>
              </TimeText>
              <EventDescription
                dangerouslySetInnerHTML={{ __html: topic.description }}
              />
              {topic.speakers.length > 1 && (
                <>
                  <ParticipantsInfo>
                    {t(`eventView:debateparticipants`)}:
                  </ParticipantsInfo>
                  <UnderlinedLinkWrapper>
                    {topic.speakers.map((speaker, index) => (
                      <Fragment key={index}>
                        <Link to={`/speaker/${speaker.slug}`}>
                          {speaker.name}
                        </Link>
                        {index < topic.speakers.length - 2 && <>, </>}
                        {index === topic.speakers.length - 2 && <> and </>}
                      </Fragment>
                    ))}
                  </UnderlinedLinkWrapper>
                </>
              )}
            </TopicContainer>
          ))}
        </Col>
        {!!speakers && (
          <Col startMD={speakers.length === 1 ? 8 : 1} endMD={11}>
            <SpeakerContainer>
              {speakers.map((speaker) => (
                <EventSpeaker
                  key={speaker.id}
                  speaker={speaker}
                  isLink={speakers.length !== 1}
                />
              ))}
            </SpeakerContainer>
          </Col>
        )}
      </StyledRow>
    </Layout>
  );
};

import { FC, ReactNode } from "react";

interface Props<PositivePropsType, NegativePropsType> {
  readonly condition: boolean;
  readonly PositiveWrapper: FC<PositivePropsType>;
  readonly positiveProps: PositivePropsType;
  readonly NegativeWrapper: FC<NegativePropsType>;
  readonly negativeProps: NegativePropsType;
  readonly children: ReactNode;
}

export const ConditionalWrapper = <PositivePropsType extends {}, NegativePropsType extends {}>({
  condition,
  PositiveWrapper,
  positiveProps,
  NegativeWrapper,
  negativeProps,
  children,
}: Props<PositivePropsType, NegativePropsType>) => {
  return (
    <>
      {condition ? (
        <PositiveWrapper {...positiveProps}>{children}</PositiveWrapper>
      ) : (
        <NegativeWrapper {...negativeProps}>{children}</NegativeWrapper>
      )}
    </>
  );
};

import { MouseEvent as ReactMouseEvent } from "react";

export const onNavLinkClickCallback = (
  e: ReactMouseEvent<HTMLAnchorElement, MouseEvent>,
  link: string,
  pathname: string
) => {
  if (link.substring(0, 2) === "/#" && pathname === "/") {
    try {
      const element = document.querySelector(link.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        e.preventDefault();
      }
    } finally {
    }
  }
};

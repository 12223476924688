import { FC, ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 2560px;
  margin: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  flex: 1 0 auto;
`;

interface Props {
  header?: ReactNode;
  footer?: ReactNode;
}

export const Layout: FC<Props> = ({ children, header, footer }) => {
  return (
    <Container>
      {header}
      <Content>
        <main>{children}</main>
      </Content>
      {footer}
    </Container>
  );
};

import { FC, useEffect, useRef } from "react";
import "vanilla-cookieconsent";
import "../CookieConsent/cookieConsent.scss";
import { GradientA } from "components/GradientLink";

export const CookieConsent: FC = () => {
  let cc = useRef<any>(null);

  useEffect(() => {
    const win = window as Window &
      typeof globalThis & { initCookieConsent: () => any; CookieConsent: any };

    // ./assets/js/cookieconsent.js has to be load before
    if (!win.initCookieConsent) {
      return;
    }
    cc.current = win.initCookieConsent();
    win.CookieConsent = cc.current;

    cc.current.run({
      current_lang: localStorage.getItem("lang"),
      autoclear_cookies: true, // default: false
      cookie_name: "pcdc-cookieconsent", // default: 'cc_cookie'
      force_consent: false,
      // remove_cookie_tables: true,

      gui_options: {
        consent_modal: {
          layout: "bar", // box,cloud,bar
          position: "bottom center", // bottom,middle,top + left,right,center
          transition: "slide", // zoom,slide
        },
        settings_modal: {
          layout: "box", // box,bar
          // position: 'left',                // right,left (available only if bar layout selected)
          transition: "slide", // zoom,slide
        },
      },

      onAccept: (cookie: any) => {},

      onChange: (cookie: any, changed_preferences: any) => {},

      languages: {
        cz: {
          consent_modal: {
            title: "Používáme cookies",
            description:
              "Díky využití souborů cookies zjišťujeme, co je pro uživatele zajímavé a analyzujeme naši propagaci na sociálních sítích. Analýza návštěvnosti nám pomáhá neustále zlepšovat náš web a umožňuje nám lépe připravovat i samotnou konferenci. Svůj souhlas můžete kdykoliv odvolat.<br><br> Přečtěte si naše zásady o <a href='https://operatorict.cz/osobni-udaje/' target='_blank' rel='noreferrer'>zpracovnání osobních údajů.</a>",
            primary_btn: {
              text: "Povolit vše",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Nastavení cookies",
              role: "c-settings", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Přizpůsobit nastavení cookies",
            save_settings_btn: "Uložit nastavení",
            accept_all_btn: "Povolit vše",
            reject_all_btn: "Povolit nezbytné",
            close_btn_label: "Zavřít",
            blocks: [
              {
                title: "Nezbytně nutné cookies",
                description:
                  "Tyto cookies pomáhají, aby webová stránka byla použitelná a fungovala správně. Ve výchozím nastavení jsou povoleny a nelze je zakázat.",
                toggle: {
                  value: "functional_storage",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Statistické",
                description:
                  "Statistické cookies pomáhají majitelům webových stránek, aby porozuměli, jak návštěvníci používají webové stránky. Tyto informace mohou být také použity k měření účinnosti našich marketingových kampaní nebo k vytvoření personalizovaného prostředí na webu.",
                toggle: {
                  value: "analytics_storage", // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: "Soubory cookie třetích stran",
                description:
                  "V některých zvláštních případech používáme také soubory cookie poskytované důvěryhodnými třetími stranami. V následující části jsou podrobně popsány soubory cookie třetích stran, se kterými se můžete na těchto stránkách setkat. <br><br><p style='padding-left:1rem'>Tyto stránky používají službu Google Analytics, která je jedním z nejrozšířenějších a nejdůvěryhodnějších analytických řešení na webu, které nám pomáhá porozumět tomu, jak stránky používáte, a způsobům, jakými můžeme zlepšit vaše zkušenosti. Tyto soubory cookie mohou sledovat například dobu, kterou na webu strávíte, a navštívené stránky, abychom mohli pokračovat ve vytváření zajímavého obsahu.</p><br><br> Další informace o souborech cookie služby Google Analytics naleznete na oficiální stránce Google Analytics.<br><br><p style='padding-left:1rem'>Na těchto stránkách také používáme tlačítka a/nebo pluginy sociálních médií, které vám umožňují různými způsoby se propojit s vaší sociální sítí. Pro jejich fungování sociální sítě Facebook a Instagram nastaví prostřednictvím našich stránek soubory cookie, které mohou být použity k vylepšení vašeho profilu na jejich stránkách nebo přispět k údajům, které mají k dispozici pro různé účely uvedené v jejich zásadách ochrany osobních údajů.</p><br>",
                toggle: {
                  value: "ad_storage", // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
        en: {
          consent_modal: {
            title: "We use cookies",
            description:
              "By using cookies, we find out what is interesting to our users and we analyze our social media promotion. Analyzing traffic helps us to continuously improve our website and allows us to better prepare the conference itself. You can withdraw your consent at any time.<br><br>Read our policy on <a href='https://operatorict.cz/en/personal-data-protection/' target='_blank' rel='noreferrer'>the processing of personal data.</a>",
            primary_btn: {
              text: "Allow all",
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Settings",
              role: "c-settings", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: "Cookie preferences",
            save_settings_btn: "Save settings",
            accept_all_btn: "Accept all",
            reject_all_btn: "Reject all",
            close_btn_label: "Close",
            blocks: [
              {
                title: "Strictly necessary cookies",
                description:
                  "These cookies help to make the website usable and function properly. They are enabled by default and cannot be disabled.",
                toggle: {
                  value: "functional_storage",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: "Analytics and Customisation Cookies",
                description:
                  "These cookies collect information that can help us understand how our websites are being used. This information can also be used to measure effectiveness in our marketing campaigns or to curate a personalised site experience for you.",
                toggle: {
                  value: "analytics_storage", // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
              {
                title: "Third Party Cookies",
                description:
                  "In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.<br><br><p style='padding-left:1rem'>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</p><br><br>For more information on Google Analytics cookies, see the official Google Analytics page.<br><br><p style='padding-left:1rem'>We also use social media buttons and/or plugins on this site that allow you to connect with your social network in various ways. For these to work the social media sites Facebook, and Instagram will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</p>",
                toggle: {
                  value: "ad_storage", // there are no default categories => you specify them
                  enabled: false,
                  readonly: false,
                },
              },
            ],
          },
        },
      },
    });
  }, []);

  return (
    <div>
      <GradientA
        href="#"
        className="p-2"
        onClick={() => cc.current && cc.current.showSettings()}
        data-cc="c-settings"
      >
        Cookies
      </GradientA>
    </div>
  );
};

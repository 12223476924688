import styled from "styled-components";
import { queries } from "utils/queries";

export const TextContainer = styled.div`
  & p {
    font-size: 16px;
    line-height: 24px;

    ${queries.md} {
      font-size: 20px;
      line-height: 32px;
    }

    &:not(:first-child) {
      margin-top: 24px;
    }
  }
`;
